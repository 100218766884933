import React from 'react'
import Page from './Page.js'
import Search from "../util/Search";
import RecommendationShort from "../widgets/RecommendationShort";
import Backend from "../util/Backend";
import $ from "jquery";
import {InputGroup} from "react-bootstrap";
import LocationFinder from "../widgets/LocationFinder";

class FindTalents extends Page {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: this.props.params.query ? true : false,
            recommendations: [],
            query: this.props.params.query,
            employment: '',
            relocate: '',
            location: '',
            latitude:undefined,
            longitude: undefined,
        }
        let params = {};
        if (this.props.params && this.props.params.query) {
            const words = this.props.params.query.split('|');
            params.text = words[0];
            this.state.query = params.text
            if (words[1] && words[1] != "") {
                this.state.experience = words[1]
                switch (words[1]) {
                    case '5':
                        params.minExperience = 5
                        break;
                    case '4':
                        params.minExperience = 3
                        params.maxExperience = 6
                        break;
                    case '3':
                        params.minExperience = 1
                        params.maxExperience = 4
                        break;
                    default:
                        params.minExperience = 0
                        params.maxExperience = 2
                        break;
                }
            }

            if (words[2] && words[2] != "") {
                this.state.employment = words[2]
                params.employmentType=[]
                const etypes = words[2].split(',')
                etypes.forEach(t=>{
                    params.employmentType.push(t)
                })

            }
            if (words[3] && words[3] != "") {
                this.state.relocate = words[3]
                params.relocate=[]
                const etypes = words[3].split(',')
                etypes.forEach(t=>{
                    switch (words[1]) {
                        case '1':
                            if (words[4] && words[4] != "") {
                                this.state.location= words[4];
                                params.location=words[4];
                            }
                            break;
                        case '2':
                            params.relocate.push("1")
                            params.relocate.push("0")
                            if (words[4] && words[4] != "") {
                                this.state.location= words[4];
                                params.location=words[4];
                            }
                            break;
                        default:
                            params.relocate.push("0")
                            params.relocate.push("1c")
                            break;
                    }
                })
            }

            if (words[5] && words[5] != "") {
                this.state.latitude= words[5];
                params.geoLocation={latitude: words[5]}
            }
            if (words[6] && words[6] != "") {
                this.state.longitude= words[6];
                params.geoLocation.longitude= words[6];
            }
        }
        let self = this;
        //console.log("search params",params)
        if (this.state.query && this.state.query.length > 0) {
            Backend.do("POST", "/search/findtalents", params, function (response) {
                console.log(response);
                if (response.error || response.errors) {
                    $('.error').removeClass('d-none').text(response.errors ? response.errors : response.error)
                } else {
                    self.onLoad(response);
                }
            }, true)
        } else
            this.state.isLoading = false;


    }

    onLoad = (response) => {
        this.state.recommendations = []
        response.forEach(hit => {
            let recommendation = {
                "id": hit.id,
                "active": hit.active,
                "avatar": "/api/recommendations/" + hit.id + "/avatar/image",
                "age": hit.age,
                "firstName": hit.firstName,
                "lastName": hit.lastName,
                "location": hit.location,
                "relocation": hit.relocate,
                "text": hit.text,
                "price": hit.price
            }
            this.state.recommendations.push(recommendation)
        })

        //console.log("recommends",this.state.recommendations)
        this.state.isLoading = false;
        this.setState(this.state)
    }
    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.onSearch();
        }
    }
    onSearch = (e) => {
        const element = document.getElementById('searchInput');
        let qry = element.value;
        let experience = document.querySelector('input[name="experienceRadio"]:checked');
        let ar = []
        if (document.getElementById('checkboxEmployment1').checked){
            ar.push("1");
            ar.push("0");
        }

        if (document.getElementById('checkboxEmployment2').checked){
            ar.push("2");
            ar.push("0");
        }

        if (document.getElementById('checkboxEmployment3').checked) {
            ar.push("0");
            ar.push("3");
            ar.push("4");
            ar.push("5");
            ar.push("6");
            ar.push("7");
            ar.push("8");
        }
        let employment = ar.length > 0 ? ar.toString() : undefined;
        let location =this.state.location;
        let relocate = document.querySelector('input[name="relocationRadio"]:checked');

        let subqry = undefined;
        if (experience || employment || relocate || location)
            subqry = "|" + (experience ? experience.value : "") + "|" + (employment ? (employment) : "") + "|" + (relocate ? relocate.value : "")+ "|" + (location ? location : "")+ "|" + (this.state.latitude ? this.state.latitude : "")+ "|" + (this.state.longitude ? this.state.longitude : "")
        window.location.replace('/find/talents/' + qry + (subqry ? subqry : ""))
    }

    renderMain() {
        var us = this.state.recommendations
        const {isLoading} = this.state;
        if (isLoading) {
            return null;
        }
        return (
            <div className={"find-talents ps-4 pe-4"}>
                <div className={"row pt-1"}>
                    <div className={"col12 d-flex"}>
                        {/*                      <button className="btn bg-white d-none d-md-block rounded-circle d-inline-block float-end p-3 me-3"
                                type="button"><img src="/images/arrow3.png"/></button>*/}
                        <input id="searchInput" className="form-control form-rounded rounded-5 form-control-lg me-3"
                               type="text" placeholder="Job title, skills, any keyword..." aria-label=".form-control-lg"
                               defaultValue={this.state.query} onKeyDown={this.handleKeyDown}>
                        </input>
                        <button className="btn btn-dark rounded-circle p-3" onClick={this.onSearch}>
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M24.3956 25.3338L25.6285 24.1009L20.7372 19.2096L19.5043 20.4425L24.3956 25.3338Z"
                                    fill="white"/>
                                <circle cx="15" cy="15" r="7.25" stroke="white" strokeWidth="1.5"/>
                            </svg>

                        </button>
                    </div>
                </div>
                <div className={"row"}>

                    <div className={"col-md-7 col-lg-9"}>
                        {
                            this.state.query  && us.length==0 && <div className="col-12 mt-5 text-center rounded-pill no-results alert alert-light" role="alert">
                            Nothing was found
                            </div>
                        }

                        <div className="row row-cols-1 row-cols-lg-3 g-1">
                            {(function (rows, i, len) {
                                while (i < len) {
                                    rows.push(<div key={i} className="col">
                                        <RecommendationShort json={us[i]}/>
                                    </div>)
                                    i++
                                }
                                return rows;
                            })([], 0, us.length)}
                        </div>
                    </div>
                    <div className={"col-md-5 col-lg-3"}>
                        <form className={"bg-white p-4 rounded-4 mt-2"}>
                            <fieldset>
                                <legend>Experience</legend>
                                <div className="d-flex justify-content-between mb-3">
                                    <label className="form-check-label">
                                        over 5 years
                                    </label>
                                    <input className="form-check-input" type="radio" value="5"
                                           checked={this.state.experience == '5'} onChange={this.onSearch}
                                           id="checkboxExperience1" name="experienceRadio"/>
                                </div>
                                <div className="d-flex justify-content-between mb-3">
                                    <label className="form-check-label">
                                        3-5 years
                                    </label>
                                    <input className="form-check-input" type="radio" value="4"
                                           checked={this.state.experience == '4'} onChange={this.onSearch}
                                           id="checkboxExperience2" name="experienceRadio"/>
                                </div>
                                <div className="d-flex justify-content-between mb-3">
                                    <label className="form-check-label">
                                        1-3 years
                                    </label>
                                    <input className="form-check-input" type="radio" value="3"
                                           checked={this.state.experience == '3'} onChange={this.onSearch}
                                           id="checkboxExperience3" name="experienceRadio"/>
                                </div>
                                <div className="d-flex justify-content-between mb-3">
                                    <label className="form-check-label">
                                        up to 1 year
                                    </label>
                                    <input className="form-check-input" type="radio" value="1"
                                           checked={this.state.experience == '1'} onChange={this.onSearch}
                                           id="checkboxExperience4" name="experienceRadio"/>
                                </div>
                            </fieldset>
                            <fieldset className={"mt-3"}>
                                <legend>Type of employment</legend>
                                <div className="d-flex justify-content-between mb-3">
                                    <label className="form-check-label">
                                        Full-time
                                    </label>
                                    <input className="form-check-input" type="checkbox"
                                           checked={this.state.employment.includes('1')} onChange={this.onSearch}
                                           id="checkboxEmployment1"/>
                                </div>
                                <div className="d-flex justify-content-between mb-3">
                                    <label className="form-check-label">
                                        Part-time
                                    </label>
                                    <input className="form-check-input" type="checkbox"
                                           checked={this.state.employment.includes('2')} onChange={this.onSearch}
                                           id="checkboxEmployment2"/>
                                </div>
                                <div className="d-flex justify-content-between mb-3">
                                    <label className="form-check-label">
                                        Temporary
                                    </label>
                                    <input className="form-check-input" type="checkbox"
                                           checked={this.state.employment.includes('3')} onChange={this.onSearch}
                                           id="checkboxEmployment3"/>
                                </div>
                            </fieldset>
                            <fieldset className={"mt-3"}>
                                <legend>Relocate</legend>
                                <div className="d-flex justify-content-between mb-3">
                                    <label className="form-check-label">
                                        Live in region
                                    </label>
                                    <input className="form-check-input" type="radio"  value="1" name={"relocationRadio"}
                                           checked={this.state.relocate.includes('1')} onChange={this.onSearch}
                                           />
                                </div>
                                <div className="d-flex justify-content-between mb-3">
                                    <label className="form-check-label">
                                        Live & ready to relocate
                                    </label>
                                    <input className="form-check-input" type="radio" value="2" name={"relocationRadio"}
                                           checked={this.state.relocate.includes('2')} onChange={this.onSearch}
                                           />
                                </div>
                                <div className="d-flex justify-content-between mb-3">
                                    <label className="form-check-label">
                                        Ready to relocate
                                    </label>
                                    <input className="form-check-input" type="radio" value="3" name={"relocationRadio"}
                                           checked={this.state.relocate.includes('3')} onChange={this.onSearch}
                                           />
                                </div>
                            </fieldset>
                            <fieldset className={"mt-3"}>
                                <legend>Location</legend>
                                <div className="d-flex justify-content-between mb-3">
                                    <label className="form-check-label">
                                        Remote work
                                    </label>
                                    <input className="form-check-input" type="checkbox"
                                           id="checkboxRelocate1"/>
                                </div>
                                <LocationFinder className={"text-muted text-end"}
                                                defaultInputValue={this.state.location ? this.state.location : ""}
                                                placeholder={"Type Location Name"}
                                                onChange={(features) => {
                                                    //console.log("onChange",features)
                                                    let feature = features[0]
                                                    let location = ""
                                                    if (feature) {
                                                        location = feature.properties.city + ", " + feature.properties.country;
                                                        this.setState({location: location});
                                                        this.setState({latitude: feature.geometry.coordinates[0]})
                                                        this.setState({longitude: feature.geometry.coordinates[1]})
                                                    }

                                                }}
                                />
                            </fieldset>
                            <fieldset className={"mt-3"}>
                                <legend>Language</legend>
                                <select className="form-select form-control text-secondary rounded-pill"
                                        aria-label=".form-select-sm example"
                                    // onChange={}
                                >
                                    <option value="0">Select Language</option>
                                    <option value="en">English</option>
                                    <option value="ru">Russian</option>
                                </select>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default FindTalents;