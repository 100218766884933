
class Backend {
    static async do(method, url, params, complete_callback, async) {
        if (!sessionStorage.getItem('authorization')) {
            //throw  new Error('No authorization token!');
            window.location.replace('/auth');
        }
        /*console.log(method, url, params)*/
        let fetchParams = {
            method: method,
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem('authorization'),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }
        if (method != 'GET') {
            fetchParams.body = JSON.stringify(params)
        }
        console.log('params',params)
        if(async){
            await fetch(
                process.env.REACT_APP_BACKEND_URL + url + (method == 'GET' ? "?" + new URLSearchParams(params) : ""), fetchParams
                , async).then((response) => {
                try {
                    if (response.ok) {
                        let json = response.json();
                        json.then((data) => {
                            /*console.log(data)*/
                            complete_callback(data);
                        }).catch((err) => {
                            console.log(err.message);
                            complete_callback({});
                        });

                    } else {
                        if (response.status == 401)
                            window.location.replace('/auth');
                        else
                            complete_callback({error: "Error: " + response.statusText, status: response.status})
                    }

                } catch (e) {
                    console.log("Couldn't parse backend response", response, e)
                }
            });
        }else{
            /*console.log("sync fetch")*/
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + url + (method == 'GET' ? "?" + new URLSearchParams(params) : ""), fetchParams)
                .then(response => response);
            /*console.log("responce",response)*/
            try {
                if (response.ok) {
                    let json = response.json();
                    await json.then((data) => {
                        /*console.log(data)*/
                        complete_callback(data);
                    }).catch((err) => {
                        console.log(err.message);
                        complete_callback({});
                    });

                } else {
                    if (response.status == 401)
                        window.location.replace('/auth');
                    else
                        complete_callback({error: "Error: " + response.statusText, status: response.status})
                }

            } catch (e) {
                console.log("Couldn't parse backend response", response, e)
            }
        }

    }
}
export default Backend;