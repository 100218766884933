// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all and (min-height: 1024px) {
.home-title {
    top: 330px;
    width: 100%;
    font-weight: 800;
    font-size: 140px;
    line-height: 90%;
    }

    .arrow-container .left-arrow, .arrow-container .mid-arrow, .arrow-container .right-arrow{
            opacity: 1;
            }
    .about .faces-container img{
           width: 1500px;
       }
}
`, "",{"version":3,"sources":["webpack://./src/scss/media_height_1024.css"],"names":[],"mappings":"AAAA;AACA;IACI,UAAU;IACV,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB;;IAEA;YACQ,UAAU;YACV;IACR;WACO,aAAa;OACjB;AACP","sourcesContent":["@media all and (min-height: 1024px) {\n.home-title {\n    top: 330px;\n    width: 100%;\n    font-weight: 800;\n    font-size: 140px;\n    line-height: 90%;\n    }\n\n    .arrow-container .left-arrow, .arrow-container .mid-arrow, .arrow-container .right-arrow{\n            opacity: 1;\n            }\n    .about .faces-container img{\n           width: 1500px;\n       }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
