import React from 'react';

class Footer1 extends React.Component{

    render() {
        return (
            <div className="row mt-5 justify-content-center">
                <div className="join d-md-flex mb-5">
                    <div className={"form p-3 mb-3 rounded-5" + (this.props.invert ? " bg-light":"")}>
                        <div className="title p-3">
                            join our newsletter
                            to get hiring tips and news!
                        </div>
                        <div className="inputs d-md-flex">
                            <input type="text" className="form-control rounded-pill p-3 mb-3 mb-md-0" placeholder="email" aria-label="email" />
                            <button className="btn btn-primary rounded-circle ms-2 p-1 d-block" type="button" >
                                <img src="/images/arrow.png"/>
                            </button>
                        </div>
                    </div>
                    <div className="links p-md-5">
                        <div className="row mb-3">
                            <div className="col-6">
                                <a className="p-2" href="/find/talents/">Search</a>
                            </div>
                            <div className="col-6">
                                <a className="p-2" href="/recommendation/create">RECOMmEND</a>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-6">
                                <a className="p-2" href="#">Jobs</a>
                            </div>
                            <div className="col-6">
                                <a className="p-2" href="#">about</a>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-6">
                                <a className="p-2" href="/find/talents">FIND TALENTS</a>
                            </div>
                            <div className="col-6">
                                <a className="p-2" href="#">contact us</a>
                            </div>
                        </div>
                        <div className="w-100 mt-5">
                            <a className="social-link">
                                <img src="/images/in.png"/>
                            </a>
                            <a className="social-link ps-4 ps-xl-5">
                                <img src="/images/tw.png"/>
                            </a>
                            <a className="social-link ps-4 ps-xl-5">
                                <img src="/images/fb.png"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Footer1;