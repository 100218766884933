// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all and (min-width: 1300px) {
   .home-title {
       width: 100%;
       font-weight: 800;
       font-size: 140px;
       line-height: 90%;
       }

    .about .about-msg {
         margin-right:150px;
    }
    .about .join .form .title{
        font-weight: 800;
        font-size: 48px;
        line-height: 117%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/scss/media_width_1300.css"],"names":[],"mappings":"AAAA;GACG;OACI,WAAW;OACX,gBAAgB;OAChB,gBAAgB;OAChB,gBAAgB;OAChB;;IAEH;SACK,kBAAkB;IACvB;IACA;QACI,gBAAgB;QAChB,eAAe;QACf,iBAAiB;IACrB;AACJ","sourcesContent":["@media all and (min-width: 1300px) {\n   .home-title {\n       width: 100%;\n       font-weight: 800;\n       font-size: 140px;\n       line-height: 90%;\n       }\n\n    .about .about-msg {\n         margin-right:150px;\n    }\n    .about .join .form .title{\n        font-weight: 800;\n        font-size: 48px;\n        line-height: 117%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
