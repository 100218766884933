import React from 'react';
import moment from "moment";
import RecommendationShort from "./RecommendationShort";
import Backend from "../util/Backend";
import Select from 'react-select'
import $ from "jquery";

class JobShort extends React.Component {
    id;
    active;
    actions;
    date;
    avatar;
    name;
    geoLocation;
    gender;
    search;
    createdAt;
    modifiedAt;
    filter_options =[{label:"All",value:"All"},{label:"New",value:"New"},{label:"Screening call",value:"Screening call"},{label:"Assessment test",value:"Assessment test"},{label:"In-person interviewing",value:"In-person interviewing"},{label:"Background check",value:"Background check"},{label:"Reference checks",value:"Reference checks"},{label:"Decision and job offer",value:"Decision and job offer"}]

    constructor(props) {
        super(props);
        if(props.json){
            this.data = props.json
            this.id=props.json.id;
            this.active = props.json.active;
            if(props.json.avatar && props.json.avatar.bytes){
                this.avatar="data:"+ props.json.avatar.contentType+";base64,"+props.json.avatar.bytes;
            }else
                this.avatar = props.json.avatar;
            this.vacancy = props.json.vacancy;
            this.companyName = props.json.companyName;
            this.experience=props.json.experience;
            this.location=props.json.location;
            this.employmentType=props.json.employmentType
            this.relocation=props.json.relocation
            this.text = props.json.text;
            this.hunter = props.json.hunter;
            this.date=moment(props.json.modifiedAt).format("DD MM YYYY");
            this.geoLocation=props.json.geoLocation;
            this.gender=props.json.gender;
            this.createdAt=props.json.createdAt
            this.modifiedAt=props.json.modifiedAt
            this.actions=""
            let user = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : undefined;
            if(user && props.json && (!props.json.hunter || (props.json.hunter && props.json.hunter.id==user.id))){
                this.actions=this.actions+" edit delete"+(this.active ? " hide" : " publish");
            }
        }else {
            this.id=props.id;
            this.active = props.active;
            this.actions = props.actions ? props.actions : "";
            this.date=props.date;
            this.avatar= props.avatar;
            this.vacancy = props.vacancy;
            this.hunter = props.hunter;
            this.companyName = props.companyName;
        }
        this.search=props.search;
        if(!this.avatar)
            this.avatar="/api/jobs/"+this.id+"/avatar/image"
        this.state = {recommendations:[], isLoading: true, id: this.id,
            actions: this.actions, active: this.active};
    }
    componentDidMount() {
        if(this.search){
            let params={}
            params.id=this.id;
            params.companyName=this.companyName
            params.experience=this.experience
            params.employmentType=this.employmentType
            params.relocation=this.relocation
            params.location=this.location
            params.text=this.text
                let user = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : undefined;
            if(user){
                params.hunter={id:user.id}
            }

            params.active=this.active
            params.geoLocation=this.geoLocation
            params.vacancy=this.vacancy
            params.age=this.age
            params.gender=this.gender
            params.createdAt=this.createdAt
            params.modifiedAt=this.modifiedAt

            Backend.do("POST", "/search/", params,  (response) => {
                if(response.error || response.errors ){
                    console.log(response.errors ? response.errors : response.error)
                }else{
                    console.log("Got recommendations for job",response)
                    this.setState({recommendations: response, isLoading:false})
                }
            })
        }else{
            this.setState({isLoading:false})
        }
    }
    async handleClickHide(e) {
        e.stopPropagation();
        let params = {id:this.state.id, active: false};
        let c = this;
        await Backend.do("POST","/jobs/activation", params, function (response) {
            c.state.actions = c.state.actions.replace(' hide', '') + " publish";
            c.state.active=false;
            c.setState(c.state)
        })
    }

    async handleClickPublish(e) {
        e.stopPropagation();
        let params = {id:this.state.id, active: true};
        let c = this;
        await Backend.do("POST","/jobs/activation", params, function (response) {
            c.state.actions = c.state.actions.replace(' publish', '') + " hide";
            c.state.active=true;
            c.setState(c.state)
        })
    }
    async onChangeFilter (value) {
        this.state.showStatusEditor=false
        this.state.filter=value.label
        this.setState(this.state)
    }
    render() {
        console.log("RENDER")
        var rs = this.state.recommendations;
        var self=this
        var job=this.id;
        return (
            <div>
                <div className="card recommendation-short bg-white m-md-2 mb-2">
                    <div className="card-header d-flex justify-content-between align-items-center">

                    </div>
                    <div className="card-body">
                        <div className="card-title">
                            <div className="job-badge  d-inline-flex">
                                <img src={this.avatar} className="job-avatar align-middle"/>
                                <div className="job-info w-100 ps-2 d-flex align-content-start flex-wrap">
                                     <div className="date">
                                             {this.date}
                                      </div>
                                    <div className="d-inline-block">
                                        <div className="job-name">{this.vacancy}</div>
                                        <div className="company">{this.companyName}</div>
                                    </div>

                                    <div className="ations ps-2 d-inline-block">
                                        <div className={"active badge rounded-pill bg-success text-dark p-3 ms-2 " + (this.state.active ? '' : 'd-none')}>Active</div>
                                        <div className={"inactive badge rounded-pill bg-secondary text-white p-3 ms-2 " + (this.state.active ? 'd-none' : '')}>Inactive</div>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-dark rounded-circle d-inline-block float-end ps-2 pe-2 collapse collapsed"  data-bs-toggle="collapse" href={"#job"+this.id} role="button" aria-expanded="true" aria-controls={this.id}
                                    type="button">
                                <img className="if-collapsed" src="./images/down.png"/>
                                <img className="if-not-collapsed" src="./images/up.png"/>
                            </button>
                        </div>
                    </div>
                    <div className="card-footer text-muted">
                        {this.state.actions.includes("edit") &&
                            <a href={'/job/edit/'+this.id} className="publish btn btn-grey rounded-circle me-2 p-1" ><img className="left"
                                                                                                                                     src="/images/edit.png"/></a>
                        }
                        {this.state.actions.includes("publish") &&
                            <a href="#" onClick={((e) => this.handleClickPublish(e))} className="publish btn btn-primary rounded-pill ps-3 pe-3">Publish<img
                                src="/images/arrow2.png"/></a>
                        }
                        {this.state.actions.includes("hide") &&
                            <a href="#" onClick={((e) => this.handleClickHide(e))} className="hide btn btn-secondary rounded-pill"><img className="left"
                                                                                                                                        src="/images/hide.png"/>Hide</a>
                        }
                    </div>
                <div className="collapse" id={"job"+this.id}>
                    <div className={"row"}>
                        <div className={"filter d-flex justify-content-end align-items-center pe-3  mb-3"}>
                            <Select options={this.filter_options}
                                    placeholder={"List Filter"}
                                    onChange={e => this.onChangeFilter(e)}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 10,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#FCA187FF',
                                            primary: '#FF855E',
                                        },
                                    })
                                    }
                            />

                        </div>
                    </div>
                    <div className="RowList row row-cols-1 row-cols-md-3 g-1">
                        {this.props.children}
                        {(function (rows, i, len) {
                            while (i <len) {
                                rows.push(<div key={i} className="col">
                                    {(!self.state.filter || self.state.filter=='All' ||self.state.filter==rs[i].status) &&
                                        <RecommendationShort json={rs[i]}  job={job} />
                                    }
                                </div>)
                                i++
                            }
                            return rows;
                        })([], 0, rs.length)}
                    </div>
                </div>
                </div>
            </div>
        );
    }

}
export default JobShort;
