import React from 'react';
import $ from 'jquery';
import RecommendationShort from "../../widgets/RecommendationShort";
import JobShort from "../../widgets/JobShort";
import Backend from "../../util/Backend";
class ProfileJobs extends React.Component {
    index;
    limit=100;
    state;
    myHash="#jobs"
    constructor(props) {
        super(props);
        this.index=0;
        this.state = {jobs: [], isLoading: true};
    }
    componentDidMount() {
        let params = {number: this.index, limit: this.limit}
        Backend.do("GET", "/jobs/user", params,  (response) => {
            if(response.error || response.errors ){
                //console.log(response.errors ? response.errors : response.error)
            }else{
                //console.log("Got jobs",response)
                this.setState({jobs: response})
            }
            this.setState({isLoading:false})
        })
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className={"profile-section jobs "+(this.myHash==window.location.hash ? "" : "d-none")}>
                    <div className="header mb-4 d-flex justify-content-between ms-2 align-items-center">
                        My jobs
                        <a href="/job/create" className="send btn btn-primary rounded-pill ps-3 pe-3">Create <img src="./images/plus.png"/></a>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            );
        }
        var us = this.state.jobs
        return (
            <div className={"profile-section jobs "+(this.myHash==window.location.hash ? "" : "d-none")}>
                <div className="header mb-4 d-flex justify-content-between ms-2 align-items-center">
                    My jobs
                    <a href="/job/create" className="send btn btn-primary rounded-pill ps-3 pe-3">Create <img src="./images/plus.png"/></a>
                </div>
                <div className="profile-content rounded-5 mt-4 ">
                    {(function (rows, i, len) {
                        while (i <len) {
                            rows.push(<div key={i} className="col">
                                <JobShort json={us[i]} search={true}>
                                </JobShort>
                            </div>)
                            i++
                        }
                        return rows;
                    })([], 0, us.length)}

                    {/*<JobShort id="job1" name="UI UX Designer" avatar="./images/ava4.png" date="18.03.2023" active={true}>*/}
                    {/*    <div className="row row-cols-1 row-cols-md-3 g-1">*/}
                    {/*        <div className="col">*/}
                    {/*            <RecommendationShort views="0" name="Mary" age="27 years old" avatar="./images/ava.png" actions="edit publish" text="Mary conducts research, helps businesses to test ideas. She has been working with big clients for more than 3 years. For example, she recently helped INVITRO with an order form."/>*/}
                    {/*        </div>*/}
                    {/*        <div className="col">*/}
                    {/*            <RecommendationShort views="57" viewsTrend="1" name="Cloud" age="19 years old" active="true" avatar="./images/ava3.png" actions="edit hide" text="Mary conducts research, helps businesses to test ideas. She has been working with big clients for more than 3 years. For example, she recently helped INVITRO with an order form."/>*/}
                    {/*        </div>*/}
                    {/*        <div className="col">*/}
                    {/*            <RecommendationShort views="23" viewsTrend="-1" name="Karen" age="24 years old" active="true" avatar="./images/ava2.png" actions="edit hide" text="Mary conducts research, helps businesses to test ideas. She has been working with big clients for more than 3 years. For example, she recently helped INVITRO with an order form."/>*/}
                    {/*        </div>*/}
                    {/*        <div className="col">*/}
                    {/*            <RecommendationShort views="23" viewsTrend="-1" name="Mary" age="27 years old" active="true" avatar="./images/avatar.png" actions="edit hide" text="Mary conducts research, helps businesses to test ideas. She has been working with big clients for more than 3 years. For example, she recently helped INVITRO with an order form."/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</JobShort>*/}
                    {/*<JobShort id="job2" name="Senior DevOPS" avatar="./images/ava5.png" date="01.03.2023" active={false}>*/}
                    {/*    <div className="row row-cols-1 row-cols-md-3 g-1">*/}
                    {/*        <div className="col">*/}
                    {/*            <RecommendationShort views="0" name="Mary" age="27 years old" avatar="./images/ava.png" actions="edit publish" text="Mary conducts research, helps businesses to test ideas. She has been working with big clients for more than 3 years. For example, she recently helped INVITRO with an order form."/>*/}
                    {/*        </div>*/}
                    {/*        <div className="col">*/}
                    {/*            <RecommendationShort views="57" viewsTrend="1" name="Cloud" age="19 years old" active="true" avatar="./images/ava3.png" actions="edit hide" text="Mary conducts research, helps businesses to test ideas. She has been working with big clients for more than 3 years. For example, she recently helped INVITRO with an order form."/>*/}
                    {/*        </div>*/}
                    {/*        <div className="col">*/}
                    {/*            <RecommendationShort views="23" viewsTrend="-1" name="Karen" age="24 years old" active="true" avatar="./images/ava2.png" actions="edit hide" text="Mary conducts research, helps businesses to test ideas. She has been working with big clients for more than 3 years. For example, she recently helped INVITRO with an order form."/>*/}
                    {/*        </div>*/}
                    {/*        <div className="col">*/}
                    {/*            <RecommendationShort views="23" viewsTrend="-1" name="Mary" age="27 years old" active="true" avatar="./images/avatar.png" actions="edit hide" text="Mary conducts research, helps businesses to test ideas. She has been working with big clients for more than 3 years. For example, she recently helped INVITRO with an order form."/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</JobShort>*/}
                    {/*<JobShort id="job3" name="Expeditor" avatar="./images/ava4.png" date="08.03.2023" active={false}>*/}
                    {/*    <div className="row row-cols-1 row-cols-md-3 g-1">*/}
                    {/*        <div className="col">*/}
                    {/*            <RecommendationShort views="0" name="Mary" age="27 years old" avatar="./images/ava.png" actions="edit publish" text="Mary conducts research, helps businesses to test ideas. She has been working with big clients for more than 3 years. For example, she recently helped INVITRO with an order form."/>*/}
                    {/*        </div>*/}
                    {/*        <div className="col">*/}
                    {/*            <RecommendationShort views="57" viewsTrend="1" name="Cloud" age="19 years old" active="true" avatar="./images/ava3.png" actions="edit hide" text="Mary conducts research, helps businesses to test ideas. She has been working with big clients for more than 3 years. For example, she recently helped INVITRO with an order form."/>*/}
                    {/*        </div>*/}
                    {/*        <div className="col">*/}
                    {/*            <RecommendationShort views="23" viewsTrend="-1" name="Karen" age="24 years old" active="true" avatar="./images/ava2.png" actions="edit hide" text="Mary conducts research, helps businesses to test ideas. She has been working with big clients for more than 3 years. For example, she recently helped INVITRO with an order form."/>*/}
                    {/*        </div>*/}
                    {/*        <div className="col">*/}
                    {/*            <RecommendationShort views="23" viewsTrend="-1" name="Mary" age="27 years old" active="true" avatar="./images/avatar.png" actions="edit hide" text="Mary conducts research, helps businesses to test ideas. She has been working with big clients for more than 3 years. For example, she recently helped INVITRO with an order form."/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</JobShort>*/}

                </div>
            </div>
        )
    }
}
export default ProfileJobs;