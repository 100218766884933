import React from 'react';
import $ from 'jquery';

class ProfileSubscription extends React.Component {
    render() {
        return (
            <div className="profile-section subscription d-none">

                <div className="profile-content rounded-5 p-4 ">
                    <div className="row">
                        <div className="col-4">
                            <div className="header mb-4">
                                Subscription active
                            </div>
                            <div className="label">$ 5 monthly</div>
                            <a href="#" className=" btn btn-grey rounded-pill p-2 mt-5">Unsibscribe</a>
                        </div>
                        <div className="col-8">
                            <img src="../images/subscription-active.png"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ProfileSubscription;