import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Landing from './pages/Landing'
import Auth from './pages/Auth'
import Profile from './pages/Profile'
import CreateJob from './pages/CreateJob'
import NotFound from './pages/NotFound'
import CreateRecommendation from "./pages/CreateRecommendation";
import CreateRecommendationWithRouter from "./pages/CreateRecommendationWithRouter";
import ViewRecommendationWithRouter from "./pages/ViewRecommendationWithRouter";
import FindTalentsWithRouter from "./pages/FindTalentsWithRouter";
import ContactTalentWithRouter from "./pages/ContactTalentWithRouter";
import CreateJobWithRouter from "./pages/CreateJobWithRouter";
import ViewJobWithRouter from "./pages/ViewJobWithRouter";

const App = () => {
  return (
    <Router>
        <Routes>
          <Route exact path="/" element={<Landing/>}/>
          <Route exact path="/auth" element={<Auth/>}/>
          <Route exact path="/profile" element={<Profile/>}/>
          <Route exact path="/job/create" element={<CreateJob/>}/>
          <Route exact path="/job/edit/:id" element={<CreateJobWithRouter/>}/>
          <Route exact path="/job/:id" element={<ViewJobWithRouter/>}/>
          <Route exact path="/recommendation/create" element={<CreateRecommendation/>}/>
          <Route exact path="/recommendation/edit/:id" element={<CreateRecommendationWithRouter/>}/>
          <Route exact path="/recommendation/:id" element={<ViewRecommendationWithRouter/>}/>
          <Route exact path="/find/talents" element={<FindTalentsWithRouter/>}/>
          <Route exact path="/find/talents/:query" element={<FindTalentsWithRouter/>}/>
          <Route exact path="/contact/talent/:id" element={<ContactTalentWithRouter/>}/>
          <Route exact path="/contact/recommender/:id" element={<ContactTalentWithRouter/>}/>
          <Route path="*" element={<NotFound/>}/>
        </Routes>
    </Router>
  );
}

export default App;