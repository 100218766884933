import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll'
import {Collapse} from 'bootstrap';
import sheet from '../style.scss';
import 'flag-icon-css/css/flag-icons.min.css'
import $ from 'jquery';
import Footer1 from "../widgets/Footer1";

 function Home (){
  let user = undefined;
  try{
    user = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : undefined;
  }catch(e){}
  const isLoggedIn = user  != undefined ? true : false;
  //const avatar = isLoggedIn ? process.env.REACT_APP_BACKEND_URL+"/recommendations/"+user.id+"/avatar" : "";
     const avatar= user && user.id? "/api/users/"+user.id+"/avatar/image" : "/images/avatar.png"

  var [toggle, setToggle] = useState(false);

    useEffect(() => {
        var myCollapse = document.getElementById('navbarToggle')
        var bsCollapse = new Collapse(myCollapse, {toggle: false})
        toggle ? bsCollapse.show() : bsCollapse.hide()

    })
    const logout = (e) => {
        sessionStorage.clear();
         window.location.reload(false);
      }
     const onSearch = (e) => {
         const element = document.getElementById('searchInput');
         let qry = element.value;
         window.location.replace('/find/talents/'+qry)
     }
     const handleKeyDown = (e) => {
         if (e.key === 'Enter') {
             onSearch();
         }
     }
 const stickyHeader = useRef()
  useLayoutEffect(() => {
    const mainHeader = document.getElementById('navbar')
    let fixedTop = stickyHeader.current.offsetTop
    const fixedHeader = () => {
      if (window.pageYOffset > fixedTop) {
        mainHeader.classList.add('sticky-top')
        mainHeader.classList.add('position-fixed')
        mainHeader.classList.remove('position-absolute')
        mainHeader.classList.remove('bottom-0')
        mainHeader.classList.remove('rounded-top')
      } else {
        mainHeader.classList.remove('sticky-top')
        mainHeader.classList.remove('position-fixed')
        mainHeader.classList.add('position-absolute')
        mainHeader.classList.add('bottom-0')
        mainHeader.classList.add('rounded-top')
      }
    }
    window.addEventListener('scroll', fixedHeader)
  }, [])


 return (
 <div>
    <div className="main position-relative">
        <div className='main-container face1'>
            <div className='effect-bg'>
            </div>
        </div>
        <div className='main-container face2'>
            <div className='effect-bg'>
            </div>
        </div>
        <div className='main-container face3'>
            <div className='effect-bg'>
            </div>
        </div>
        </div>
     <div className="container-fluid">
        <span className="home-title position-absolute  start-50 translate-middle">
          Find rare talents
         </span>
         <div className='face1-container position-absolute  start-50 translate-middle face1'>
        </div>
        <div className='face2-container position-absolute  start-50 translate-middle face2' >
        </div>
        <div className='face3-container position-absolute  start-50 translate-middle face3' >
         </div>
         <div className='arrow-container d-flex w-50 position-absolute  start-50  translate-middle'>
                         <div className="d-flex align-items-start align-self-center pe-3" style={{position: 'relative'}}>
                            <span className="left-label face1">May</span>
                            <span className="left-label face2">Serge</span>
                            <span className="left-label face3">Shelly</span>
                        </div>
                     <div className="d-flex align-items-start left-arrow">
                     </div>
                     <div className="flex-fill mid-arrow align-self-center">
                     </div>
                     <div className="d-flex align-items-end right-arrow">
                      </div>
                      <div className="d-flex align-items-end align-self-center ps-3" style={{position: 'relative'}}>
                          <span className="right-label face1">Engineer</span>
                          <span className="right-label face2">Sound producer</span>
                          <span className="right-label face3">Nurse</span>
                      </div>
        </div>
      <div className='find-container w-50 position-absolute  start-50 translate-middle'>
                 <div className="row g-3 align-items-center">
                     <div className="col-11">
                         <input id="searchInput" className="form-control form-rounded rounded-5 form-control-lg ms-3 me-3" type="text" placeholder="Find" aria-label=".form-control-lg" onKeyDown={handleKeyDown}>
                         </input>
                     </div>
                     <div className="col-1">
                         <button className="btn btn-dark rounded-circle p-3" onClick={onSearch}>
                         <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M24.3956 25.3338L25.6285 24.1009L20.7372 19.2096L19.5043 20.4425L24.3956 25.3338Z" fill="white"/>
                          <circle cx="15" cy="15" r="7.25" stroke="white" strokeWidth="1.5"/>
                          </svg>

                         </button>
                     </div>
                 </div>
             </div>
       <nav id="navbar"  ref={stickyHeader} className="navbar position-absolute start-0 bottom-0 w-100 rounded-top navbar-expand-lg bg-light">
          <div className="container-fluid">
           <button className="navbar-toggler" type="button" onClick={() => setToggle(toggle => !toggle)} data-bs-toggle="collapse" data-bs-target="#navbarToggle" aria-controls="navbarToggle" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
            <a className="navbar-brand ms-3" href="#">crowdhiring</a>
                <div className="collapse navbar-collapse justify-content-end" id="navbarToggle">
                  <ul className="navbar-nav ms-auto">
                        {/*<li className="nav-item">*/}
                        {/*  <a className="nav-link" href="#">Jobs</a>*/}
                        {/*</li>*/}
                    <li className="nav-item">
                      <a className="nav-link" href="/find/talents">FIND TALENTS</a>
                    </li>
                    <li className="nav-item">
                      <a href="/recommendation/create" className="nav-link ">RECOMmEND</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link ">about</a>
                    </li>
                  </ul>
                </div>
                <form className="d-flex">
                {isLoggedIn ?
                    <a href="/profile" className="profile">
                        <img src={avatar} className="avatar"/>
                    </a>
                :
                    <a href="/auth" className="login btn btn-dark rounded p-3">log in</a>
                }
              </form>
          </div>
        </nav>
      </div>
      <div className="container-fluid about pt-4">
        <div className="  faceless d-md-flex align-items-baseline">
            <span className="title d-xl-inline d-block align-self-start ps-md-4">
                stop reviewing<br/>faceless resumes
            </span>
            <figure className="about-msg align-self-end align-self-baseline p-md-3">
              Crowdhiring helps to find talents without reviewing tons of CVs.  You will get recommendations  from people who vouch  for this people.
            </figure>
        </div>
        <div className="clearfix"></div>
            <div className="row figures ps-4">
                <div className="col-4 position-relative d-table-cell">
                    <figure className="figure">
                       <img src="./images/person.png" className="ico"></img>
                       <img src="./images/il1.png" className="illustration"></img>
                       <span className="label">
                            People recommend <br/>each other
                       </span>
                    </figure>
                </div>
                <div className="col-4 position-relative d-table-cell">
                    <figure className="figure">
                       <img src="./images/search.png" className="ico"></img>
                       <img src="./images/il2.png" className="illustration"></img>
                       <span className="label">
                            Post a job or search<br/> recommendation
                       </span>
                    </figure>
                </div>
                <div className="col-4 position-relative d-table-cell">
                    <figure className="figure">
                       <img src="./images/wallet.png" className="ico"></img>
                       <img src="./images/il3.png" className="illustration"></img>
                       <span className="label">
                            Apply for jobs from your company<br/>and get AI recommendations
                       </span>
                    </figure>
                </div>
        </div>

        <div className="row faces-container">
         <ScrollContainer className="scroll-container">
            <img src="./images/il21.png"></img>
         </ScrollContainer>
        </div>
        <div className="hireworkers pt-5 pb-5">
                <div className="title d-md-inline-block">Hire workers <br/>instead of talkers</div>
            <div className="desc float-end">
                <span>
                    It's common for business to take risk and hire an employee without knowing for sure if he is as qualified as he says to be
                </span>
                <div className="br">
                </div>
                <span>
                    With crowdhiring you can be sure that referees are interested in recommending only the best candidates
                </span>
            </div>
        </div>
      <div className="clearfix"></div>
      <div className="row">
       <div className="d-flex">
          <div className='arrow2-container d-flex mt-5 mb-5'>
                 <div className="d-flex align-items-start align-self-center pe-3" style={{position: 'relative'}}>
                  <img src="./images/cup.png"></img>
                </div>
                 <div className="d-flex align-self-center align-items-start left-arrow2">
                 </div>
                 <div className="flex-fill mid-arrow2 align-self-center">
                 </div>
                 <div className="d-flex align-self-center align-items-end right-arrow2">
                  </div>
                  <div className="d-flex align-items-end align-self-center ps-3" style={{position: 'relative'}}>
                        <img src="./images/bag.png"></img>
                  </div>
            </div>
      </div>
      </div>
        <div className="row justify-content-center">
            <div className="recommendation pb-3">
                <div className="title d-md-inline-block">Got a lot of friends?<br/>Why not to help them?</div>
                <div className="desc float-end">
                    <span>
                        It's common for business to take risk and hire an employee without knowing for sure if he is as qualified as he says to be
                    </span>
                    <div className="br">
                    </div>
                    <span>
                        With crowdhiring you can be sure that referees are interested in recommending only the best candidates
                    </span>
                </div>
                <div className="clearfix"></div>
                <div className="form d-md-flex">
                    <div className="title d-block d-md-inline-block">
                    Create your first<br/> recommendation
                    </div>
                    <div className="inputs flex-md-fill d-flex flex-column flex-lg-row justify-content-end align-items-center">
                        <div className="btn-group">
                            <div className="d-flex flag-container">
                              <span id="flaglabel" className="border align-self-center rounded-circle flag-icon flag-icon-ru flag-icon-squared"></span>
                            </div>
                          <input type="text" className="form-control rounded-pill" placeholder="+7 000 000 00 00" aria-label="Phone" aria-describedby="flaglabel" />
                    </div>
                    <div className="hr d-md-none"></div>
                    <div className="buttons">
                        <a className="btn btn-primary btn-lg rounded-pill d-none d-md-block" type="button" >
                              <span className="label pr-1 d-none d-md-inline-block">Join</span><img src="./images/arrow.png"/>
                        </a>
                        <button className="btn btn-primary btn-lg rounded-circle p-2 d-block d-md-none" type="button" >
                              <img src="./images/arrow.png"/>
                        </button>
                    </div>
                    <div className="hr d-md-none"></div>

                    </div>
                </div>
        </div>
        </div>
            <Footer1 invert="true"/>
      </div>
    </div>
    );
    }

export default Home;