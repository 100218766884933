import React from 'react';
import UserBadge from "./UserBadge";

class RecommenderBatch extends UserBadge {
    recommendation;
    constructor(props) {
        super(props);
        this.recommendation= this.props.recommendation;
    }
    render() {
        return (
            <div className={"user-badge recommender-batch"}>
            <div className={" d-inline-flex w-100 "+(this.big_title? "" : "big-title")}>
                {this.avatar ?
                    <img alt="" src={this.avatar} className="avatar align-top"/>
                    :""
                }
                    <div className="user-info w-100 ps-2 d-flex justify-content-between">
                        <div className="user-name">
                            <div className={"recommender"}>Recommender:</div>
                            {this.user_name}
                        </div>
                        <div className={"d-flex align-items-center"}>
                            <a className="btn btn-dark rounded-circle d-inline-block ps-2 pe-2"
                               href={"/contact/recommender/"+this.recommendation}
                                    type="button" >
                                <img alt="" src="/images/subtract.png"/>
                            </a>
                        </div>

                </div>
            </div>
        <div className="value d-flex align-items-center">
            <span>4.7</span>
            {!this.nostars &&
                <div className="rating">
                    <div className="stars d-flex">
                        <img alt="" src="/images/starf.png"/>
                        <img alt="" src="/images/starf.png"/>
                        <img alt="" src="/images/starf.png"/>
                        <img alt="" src="/images/starf.png"/>
                        <img alt="" src="/images/starh.png"/>
                    </div>
                </div>
            }
        </div>
                <div className={"status d-flex justify-content-between mt-3"}>
                    <div className={"label"}>
                        Published
                    </div>
                    <div className={"date"}>
                        24 aug 2022
                    </div>
                </div>
        </div>
        )
    }
}
export default RecommenderBatch;