import React from 'react';
import $ from 'jquery';
import ReactDOM from "react-dom";

import Backend from '../../util/Backend.js'
import AvatarEditor from "../../widgets/AvatarEditor";


class ProfileSettings extends React.Component {

    constructor(props) {
        super(props);
        try {
            let user = JSON.parse(sessionStorage.getItem("user"));
            this.state = {
                firstName: user.firstName ? user.firstName : user.fullName,
                lastName: user.lastName,
                phone: user.phone,
                avatar: user.avatar ? user.avatar : "/api/users/"+user.id+"/avatar/image",
                enableSave: false,
            };
        }catch (e){}
    }
    onStore = async (dataUrl) => {
        let user = JSON.parse(sessionStorage.getItem("user"))
        console.log("Store data for user",user)

        let params = {};
        params.phone = this.state.phone
        params.firstName = this.state.firstName
        params.lastName = this.state.lastName
        params.fullName = params.firstName + " " + params.lastName
        var self = this;
        Backend.do("POST","/users/", params, function (response) {
            console.log(response);
            if (response.error || response.errors) {
                $('.profile-content .error').removeClass('d-none').text(response.errors ? response.errors : response.error)
            } else {
                sessionStorage.setItem("user", JSON.stringify(response));
                if($('.ava-preview').attr("changed")=='true'){
                    var avatar = $(".ava-preview").attr('data');
                    avatar = avatar.replace('url(','').replace(')','').replace(/\"/gi, "");
                    const type = avatar.split(';')[0].split('/')[1];
                    console.log("Upload avatar for user",user)
                    let params = {};
                    params.contentType = "image/" + type;
                    params.bytes = avatar;
                    params.id=user.id
                    Backend.do("POST","/users/avatar", params, function (response) {
                        console.log(response);
                        if(response.error || response.errors ){
                            $('.profile-content .error').removeClass('d-none').text(response.errors ? response.errors : response.error)
                        }else{
                            window.location.reload(true);
                        }
                    },false)
                }else{
                    window.location.reload(true);
                }
            }
        })

    }
    render() {
        return (
            <div className="profile-section settings">
                <div className="header mb-4">
                    Settings
                </div>
                <div className="profile-content rounded-5 p-4 ">
                    <div className="row form">
                        <div className="col-md-3">
                            <AvatarEditor avatar={this.state.avatar} onSelect={(val) => { $('.ava-preview').attr("data",val); $('.ava-preview').attr("changed","true"); this.setState({["enableSave"]:true}) }}/>
                        </div>
                        <div className="col-md-5">
                            <div className={"row"}>
                            <form className="d-grid gap-3 h-100">
                                <input className="form-control rounded-pill firstname" type="text"
                                       placeholder="First Name"
                                       aria-label="First Name"
                                       value={this.state.firstName}
                                       onChange={(val) => {this.setState({["firstName"]: val.target.value}); this.setState({["enableSave"]:true})}}/>
                                <input className="form-control rounded-pill lastname" type="text"
                                       placeholder="Last Name"
                                       aria-label="Last Name"
                                       value={this.state.lastName}
                                       onChange={(val) => {this.setState({["lastName"]: val.target.value}); this.setState({["enableSave"]:true})}}/>
                                <input className="form-control rounded-pill phone" type="text"
                                       placeholder="Phone Number"
                                       aria-label="Phone Number"
                                       value={this.state.phone}
                                       onChange={(val) => {  this.setState({["phone"]: val.target.value}); this.setState({["enableSave"]:true})}}/>
                            </form>
                         </div>
                            <div className={"pt-3 d-flex flex-row-reverse"}>
                                    <button type="button" className={"btn btn-primary "+(this.state.enableSave ? "" : "disabled")} onClick={() => this.onStore(this)}>Save Changes</button>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3 mt-md-0">
                            <div className="profile-email-section rounded-5 p-4 h-100">
                                <div className="form-label">Enter your e-mail to recieve updates and special offers
                                </div>
                                <div className="inputs d-flex"><input type="text"
                                                                         className="form-control rounded-pill"
                                                                         placeholder="email" aria-label="email"/>
                                    <button
                                        className="btn btn-secondary2 rounded-circle d-inline-block float-end ms-2  ps-2 pe-2"
                                        type="button">
                                        <img src="./images/arrow2.png"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="error alert alert-danger flex-fill mt-3 d-none" role="alert">
                        Error
                    </div>

                    <div className="row mt-5">
                        <div className="col-md-6">
                            <div className="upgrade rounded-4 p-4 d-flex justify-content-between mb-3">
                                <div className="d-inline">
                                    Upgrade to professional dashboard to hire talents
                                </div>
                                <div>
                                    <a
                                        className="btn btn-primary rounded-circle d-inline-block float-end ps-2 pe-2"
                                        type="button"><img
                                        src="./images/arrow2.png"/></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="bg-white rounded-4 d-flex justify-content-between mb-3 ps-2 pe-2">
                                <div className="d-inline">
                                    Waiting to new opportunities?Create recomendation to yourself
                                </div>
                                <div>
                                    <a
                                        className="btn btn-primary rounded-circle d-inline-block float-end m-2 ps-2 pe-2"
                                        href="/recommendation/create"
                                        type="button"><img
                                        src="./images/arrow2.png"/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProfileSettings;