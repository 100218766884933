import React from 'react';

class JobShort extends React.Component {
    id;
    active;
    actions;
    date;
    avatar;
    name;
    constructor(props) {
        super(props);
        this.id=props.id;
        this.active = props.active;
        this.actions = props.actions ? props.actions : "";
        this.date=props.date;
        this.avatar= props.avatar;
        if(!this.avatar)
            this.avatar="/api/jobs/"+this.id+"/avatar/image"
        this.name = props.name;
        let user = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : undefined;
        if(user && props.json && (!props.json.hunter || (props.json.hunter && props.json.hunter.id==user.id))){
            this.actions=this.actions+" edit delete"+(this.active ? "hide" : "publish");
        }
    }
    render() {
        return (
            <div>
                <div className="card recommendation-short bg-white m-md-2 mb-2">
                    <div className="card-header d-flex justify-content-between align-items-center">

                    </div>
                    <div className="card-body">
                        <div className="card-title">
                            <div className="job-badge  d-inline-flex">
                                <img src={this.avatar} className="job-avatar align-middle"/>
                                <div className="job-info w-100 ps-2">
                                     <div className="date">
                                             {this.date}
                                      </div>
                                    <div className="job-name d-inline-block">{this.name}</div>
                                    <div className="ations ps-2 d-inline-block">
                                        <div className={"active badge rounded-pill bg-success text-dark p-3 ms-2 " + (this.active ? '' : 'd-none')}>Active</div>
                                        <div className={"inactive badge rounded-pill bg-secondary text-white p-3 ms-2 " + (this.active ? 'd-none' : '')}>Inactive</div>
                                    </div>

                                </div>
                            </div>
                            <button className="btn btn-dark rounded-circle d-inline-block float-end ps-2 pe-2 collapse collapsed"  data-bs-toggle="collapse" href={"#"+this.id} role="button" aria-expanded="true" aria-controls={this.id}
                                    type="button">
                                <img className="if-collapsed" src="./images/down.png"/>
                                <img className="if-not-collapsed" src="./images/up.png"/>
                            </button>
                        </div>
                    </div>
                    <div className="card-footer text-muted">
                        {this.actions.includes("edit") &&
                            <a href={'/job/edit/'+this.id} className="publish btn btn-grey rounded-circle me-2 p-1" ><img className="left"
                                                                                                                                     src="/images/edit.png"/></a>
                        }
                        {this.actions.includes("publish") &&
                            <a href="#" className="publish btn btn-primary rounded-pill ps-3 pe-3">Publish<img
                                src="/images/arrow2.png"/></a>
                        }
                        {this.actions.includes("hide") &&
                            <a href="#" className="publish btn btn-secondary rounded-pill"><img className="left"
                                                                                                src="/images/hide.png"/>Hide</a>
                        }
                    </div>
                </div>
                <div className="collapse" id={this.id}>
                    <div className="RowList">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }

}
export default JobShort;
