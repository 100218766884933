
const searchUrl = "/json";

class Search {
    static fillDocParams(id, data){
        return  {
            "firstname":data.firstName,
            "lastname" : data.lastName,
            "content" : data.text,
            "age" : data.age,
            "experience" : data.experience,
            "sex": data.sex ? data.sex : "0",
            "relocate" : data.relocation ? data.relocation : "0",
            "empl_type" : data.employmentType ? data.employmentType : "0",
            "location" : data.location ? data.location : "",
            "db_id" : id
        }

    }
    static async creatRecommendation(id, data, complete_callback) {
        let params ={
            "index":"recommendation",
            "doc" : this.fillDocParams(id, data)
        };
        let fetchParams = {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem('authorization'),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }
        fetchParams.body = JSON.stringify(params)

        const response = await fetch(
             searchUrl + "/insert", fetchParams
            ).then((response) => {
            try {
                console.log("manticore response",response)
                if (response.ok) {
                    let json = response.json();
                    json.then((data) => {
                        if(complete_callback)
                            complete_callback(data);
                    })
                } else {
                    if (response.status == 401)
                        window.location.replace('/auth');
                    else
                        if(complete_callback)
                            complete_callback({error: "Error: " + response.statusText, status: response.status})
                }

            } catch (e) {
                console.log("Couldn't parse search response", response, e)
            }
        });
    }

    static async updateRecommendation(id, data, complete_callback) {
        let params ={
            "index":"recommendation",
            "doc" : this.fillDocParams(id, data)
        };
        let fetchParams = {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem('authorization'),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }
        console.log("replace params",params)
        fetchParams.body = JSON.stringify(params)

        const response = await fetch(
            searchUrl + "/replace", fetchParams
        ).then((response) => {
            try {
                console.log("manticore response",response)
                if (response.ok) {
                    let json = response.json();
                    json.then((data) => {
                        if(complete_callback)
                            complete_callback(data);
                    })
                } else {
                    if (response.status == 401)
                        window.location.replace('/auth');
                    else
                    if(complete_callback)
                        complete_callback({error: "Error: " + response.statusText, status: response.status})
                }

            } catch (e) {
                console.log("Couldn't parse search response", response, e)
            }
        });
    }

    static async searchRecommendations(data, callback) {
        let params ={
            "index":"recommendation",
            "query":
                {
                    "query_string": data.query
                },
        };
        let fetchParams = {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + sessionStorage.getItem('authorization'),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }
        fetchParams.body = JSON.stringify(params)
        console.log("search params",fetchParams)

        const response = await fetch(
            searchUrl + "/search", fetchParams
        ).then((response) => {
            try {
                console.log("manticore response",response)
                if (response.ok) {
                    let json = response.json();
                    json.then((data) => {
                        if(callback)
                            callback(data);
                    })
                } else {
                    if (response.status == 401)
                        window.location.replace('/auth');
                    else
                    if(callback)
                        callback({error: "Error: " + response.statusText, status: response.status})
                }

            } catch (e) {
                console.log("Couldn't parse search response", response, e)
            }
        });

    }
}
export default Search;