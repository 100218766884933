import React from 'react';
import Backend from "../util/Backend";

class UserBadge extends React.Component {
    user_id;
    user_name;
    avatar;
    nostars;
    age;
    constructor(props) {
        super(props);
        this.state = {isLoading: true};
        try{
            this.user_id = props.id;
            this.user_name = props.name;
            this.big_title=props.big_title===true;
        }catch(e){}
        this.avatar = this.user_id  !== undefined ? process.env.REACT_APP_BACKEND_URL+"/recommendations/"+this.user_id+"/avatar/image" : "";

        this.nostars= props.nostars;
        this.age = props.age;
        if(props.json){
            this.user_id=props.json.id;
            this.big_title=props.json.big_title;
            this.user_name= this.props.json.fullName;
            this.avatar=this.props.json.avatar;
            if(this.avatar && this.avatar.bytes){
                this.avatar="data:"+ this.props.json.avatar.contentType+";base64,"+this.props.json.avatar.bytes
            }else
                this.avatar=this.props.json.avatar;
        }
        if(props.no_avatar){
            this.avatar=null
        }else
        if(props.avatar){
            if(props.avatar && props.avatar.bytes){
                this.avatar="data:"+ this.props.avatar.contentType+";base64,"+props.avatar.bytes
            }else
                this.avatar=props.avatar;
        }else{
            this.avatar="/api/users/"+this.user_id+"/avatar/image"
        }
        if(this.user_name){
            this.state.isLoading=false;
        }
    }
    componentDidMount() {
        if(this.state.isLoading)
        {
            let params={};
            //TODO пока не понятно по какому запросу получать инфу ползователя
            if(true)return;
            Backend.do("GET", "/jobs/user", params, (response) => {
                if (response.error || response.errors) {
                    console.log(response.errors ? response.errors : response.error)
                } else {
                    console.log("Got jobs", response)
                    this.setState({jobs: response})
                }
                this.setState({isLoading: false})
            })
        }
    }
    render() {
        return (
            <div className={"user-badge d-inline-flex w-100 "+(this.big_title? "big-title" : "" )}>
                {this.avatar ?
                    <img alt="" src={this.avatar} className="avatar align-top  mt-2"/>
                    :""
                }
                    <div className="user-info w-100 ps-2">
                        <div className="user-name">{this.user_name}</div>
                        {!this.nostars &&
                            <div className="rating">
                                <div className="stars d-flex justify-content-between mb-3">
                                    <img alt="" src="/images/starf.png"/>
                                    <img alt="" src="/images/starf.png"/>
                                    <img alt="" src="/images/starf.png"/>
                                    <img alt="" src="/images/starf.png"/>
                                    <img alt="" src="/images/starh.png"/>
                                </div>
                                <div className="value flex-fill align-items-end">
                                    <span>4.7</span>
                                </div>
                            </div>
                        }
                        {this.age &&
                            <div className={"age"+ (this.avatar? "" : " mt-3 mb-3")}>
                                {this.age}
                            </div>
                        }
                </div>
            </div>
        )
    }
}
export default UserBadge;