import React from "react";
import Avatar from "react-avatar-edit";
import Backend from "../util/Backend";
import $ from 'jquery';

class AvatarEditor extends React.Component{
    croppedImage;
    onSelect;
    constructor(props) {
        super(props);
        console.log(this.props)
        this.onSelect = props.onSelect;
        this.handleLoad = this.handleLoad.bind(this);
        const src = "";
        this.state = {
            preview: "./images/avatar.png",
            enableUpload: false,
            src,
        };
        if(props.avatar){
            if(props.avatar.bytes){
                this.state.avatar="data:"+ this.props.avatar.contentType+";base64,"+props.avatar.bytes
            }else{
                this.state.avatar=props.avatar;
            }

        }
        this.onCrop = this.onCrop.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this);
        this.onChange = props.onChange;
    }

    onEditDone = async () => {
        this.onSelect(this.state.preview)
        this.state.avatar=this.state.preview
        this.onClose()
        $('.modal .btn-close').click()
    }

    onClose() {
        this.state.enableUpload=false;
        $('#avatar-editor .error').addClass('d-none')
    }

    onCrop(preview) {
        this.setState({preview});
        this.state.enableUpload=true;
    }

    onBeforeFileLoad(elem) {
        this.state.enableUpload=false;
        $('#avatar-editor .error').addClass('d-none')
        if (elem.target.files[0].size > 7168000) {
            alert("File is too big!");
            elem.target.value = "";
        }else{
            this.state.enableUpload=true;
        }
    }

    componentDidMount() {
        window.addEventListener('load', this.handleLoad);
    }

    componentWillUnmount() {
        window.removeEventListener('load', this.handleLoad)
    }

    handleLoad() {
    }

    render() {
        return (
            <div>
                <div className="avatar-container" data-bs-toggle="modal"
                     data-bs-target="#avatar-editor">
                    <div className="ava-cam">
                    </div>
                    <div className="ava-preview" style={{background: "linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), center / contain no-repeat url("+this.state.avatar+"), #C7C6C8"}}>
                    </div>
                </div>
                <div className="modal modal-lg fade" id="avatar-editor"  tabIndex="-1" aria-labelledby="avatarModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className=" d-flex justify-content-between">
                                <div className={""}>
                                    <Avatar
                                        width={390}
                                        imageWidth={390}
                                        height={295}
                                        onCrop={this.onCrop}
                                        onClose={this.onClose}
                                        onBeforeFileLoad={this.onBeforeFileLoad}
                                        src={this.state.src}
                                    />
                                </div>
                                    <div className={""}>
                                        <img src={this.state.preview? this.state.preview : "./images/avatar.png"} alt="Preview"/>
                                    </div>
                            </div>
                        </div>
                        <div className="modal-footer d-flex ">
                            <div className="alert alert-danger flex-fill error d-none" role="alert">
                                Error
                            </div>
                            <button type="button" className={"btn btn-primary "+(this.state.enableUpload ? "" : "disabled")} onClick={() => this.onEditDone()}>Ok</button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}
export default AvatarEditor;