import React from "react";
import {AsyncTypeahead, Highlighter} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css'
class LocationFinder extends React.Component{
     filterBy = () => true;
     props;
    constructor(props, context) {
        super(props, context);
        this.state = {
            isLoading: false,
            options: [],
            placeholder:'',
        };
        if(props.placeholder){
            this.state.placeholder=props.placeholder;
        }
        this.props= props;
    }
    handleSearch = (query) => {
        this.setState({isLoading:true})
        //console.log("Autocomplete location",query)
        if(!process.env.REACT_APP_GEOAPIFY_KEY){
            console.log("GEOAPIFY KEY NOT FOUND")
            this.setState({isLoading:false})
            return
        }
        var requestOptions = {
            method: 'GET',
        };
        fetch("https://api.geoapify.com/v1/geocode/autocomplete?text="+query+"&apiKey="+process.env.REACT_APP_GEOAPIFY_KEY, requestOptions)
            .then(response => response.json())
            .then(result => {
                this.setState({isLoading:false})
                console.log("Geoapify response",result)
                if(result && result.features){
                    let opts = [];
                    for(let f in result.features){
                        let feature = result.features[f];
                        if(feature && feature.properties && feature.properties.city){
                            feature.label=feature.properties.city
                        }else
                            feature.label=""
                        opts.push(feature)
                    }
                    this.setState({options: opts});
                }


            })
            .catch(error => console.log('error', error));
    };
    render(){
        return (
            <AsyncTypeahead
                {...this.props}
                filterBy={this.filterBy}
                id="location-finder"
                isLoading={this.state.isLoading}
                labelKey="label"
                minLength={3}
                className={this.className}
                onSearch={this.handleSearch}
                options={this.state.options}
                placeholder={this.state.placeholder}
                renderMenuItemChildren={(option,{text}) => (
                    <>
                        <Highlighter search={text}>{option.label}</Highlighter>
                        <div>
                            <small>{option.properties.country}</small>
                        </div>
                    </>
                )}
            />)
    }
}


export default LocationFinder;