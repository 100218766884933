import React from 'react'
import Page from './Page.js'
import Backend from "../util/Backend";
import AvatarEditor from "../widgets/AvatarEditor";
import Footer1 from "../widgets/Footer1";
import Rating from 'react-rating';
import RatingStarEmpty from "../util/RatingStarEmpty";
import RatingStarFull from "../util/RatingStarFull";
import 'flag-icon-css/css/flag-icons.min.css'

import $ from "jquery";
import {GENDERS, EMPLOYMENT, RELOCATION} from '../util/Constants'
import Search from "../util/Search";
import LocationFinder from "../widgets/LocationFinder";
import {InputGroup} from "react-bootstrap";
import parsePhoneNumber from "libphonenumber-js";

class CreateRecommendation extends Page {
    api_key="13d1f94112b2b70349d68b1d9dcdee40";
    constructor(props) {
        super(props);

        this.state = {
            avatar: {},
            data: {
                ratings: [
                    {id: 0, ratingType: {id: 0, name: 'creativity'}, ratingValue: 0},
                    {id: 0, ratingType: {id: 0, name: 'accuracy'}, ratingValue: 0},
                    {id: 0, ratingType: {id: 0, name: 'punctuality'}, ratingValue: 0},
                    {id: 0, ratingType: {id: 0, name: 'responsibility'}, ratingValue: 0}
                ]
            }
        }
        if(this.props && this.props.params && this.props.params.id){
            this.state.id=this.props.params.id
            this.state.isLoading= true;
            let params={};
            let self = this;
            Backend.do("GET", "/recommendations/"+this.props.params.id, params,  (response) => {
                if(response.error || response.errors ){
                    //console.log(response.errors ? response.errors : response.error)
                }else{
                    //console.log("Got recommendation",response)
                    self.onLoad(response);
                }
            },false)
        }
    }

    onLoad =  (response) => {
        this.state.data=response;
        if(response.avatar && response.avatar.bytes){
                this.state.avatar= "data:"+ response.avatar.contentType+";base64,"+response.avatar.bytes
        }else
            this.state.avatar="/api/recommendations/"+this.state.id+"/avatar/image"

        this.state.isLoading=false;
        this.setState(this.state)
    }
    onDelete = async  () => {
        let params = {id:this.state.id};
        await Backend.do("DELETE","/recommendations/"+this.state.id, params, function (response) {
            //console.log(response);
            window.location=document.referrer;
        })
    }
    onSave = async () => {
        let self = this;
        this.state.data.active=false;
        await Backend.do(this.state.id?"PUT":"POST","/recommendations/"+(this.state.id?this.state.id:""), this.state.data, function (response) {
            //console.log(response);
            let recommendation = response;
            if (response.error || response.errors) {
                $('.create_recommendation .error').removeClass('d-none').text(response.errors ? response.errors : response.error)
            } else {
                self.pushToManticore(response.id, !!self.state.id);
                if($('.ava-preview').attr("changed")=='true'){
                    var avatar = $(".ava-preview").attr('data');
                    avatar = avatar.replace('url(','').replace(')','').replace(/\"/gi, "");
                    const type = avatar.split(';')[0].split('/')[1];
                    let params = {};
                    params.contentType = "image/" + type;
                    params.bytes = avatar;
                    params.id=recommendation.id?recommendation.id: self.state.id;
                    //console.log("Upload avatar for recommendation",recommendation,params)
                    Backend.do("PUT", "/recommendations/"+params.id+"/avatar", params, function (response) {
                        //console.log(response);
                        if(response.error || response.errors ){
                            $('.error').removeClass('d-none').text(response.errors ? response.errors : response.error)
                        }else{
                            $('.recommendation_saved').removeClass('d-none')
                            $('.create_recommendation').addClass('d-none')
                        }
                    },false)
                }else{
                    $('.recommendation_saved').removeClass('d-none')
                    $('.create_recommendation').addClass('d-none')
                }
            }
        })
    }
    onPublish = async () => {
        let self = this;
        this.state.data.active=true;
        await Backend.do(this.state.id?"PUT":"POST","/recommendations/"+(this.state.id?this.state.id:""), this.state.data, function (response) {
            //console.log(response);
            let recommendation = response;
            if (response.error || response.errors) {
                $('.create_recommendation .error').removeClass('d-none').text(response.errors ? response.errors : response.error)
            } else {
                self.pushToManticore(response.id, !!self.state.id);
                if($('.ava-preview').attr("changed")=='true'){
                    var avatar = $(".ava-preview").attr('data');
                    avatar = avatar.replace('url(','').replace(')','').replace(/\"/gi, "");
                    const type = avatar.split(';')[0].split('/')[1];
                    let params = {};
                    params.contentType = "image/" + type;
                    params.bytes = avatar;
                    params.id=recommendation.id?recommendation.id: self.state.id;

                    //console.log("Upload avatar for recommendation",recommendation,params)
                    Backend.do("PUT", "/recommendations/"+params.id+"/avatar", params, function (response) {
                        //console.log(response);
                        if(response.error || response.errors ){
                            $('.error').removeClass('d-none').text(response.errors ? response.errors : response.error)
                        }else{
                             $('.recommendation_published').removeClass('d-none')
                             $('.create_recommendation').addClass('d-none')
                        }
                    },false)
                }else{
                    $('.recommendation_published').removeClass('d-none')
                    $('.create_recommendation').addClass('d-none')
                }
            }
        })
    }
    pushToManticore = async (id, update) => {
/*        if(!update) {
            return;
            await Search.creatRecommendation(id, this.state.data)
        }
        else
            await Search.updateRecommendation(id, this.state.data)*/
    }
    getState(callback) {
        this.setState((prevState) => {
            callback(prevState);
        });
    }
    focusElement = (e) => {
        $(e.target).parent('div').find('input').focus()
    }
    handlePhoneChange = (event) => {
        let mask = "flag-icon-*"
        $('#flaglabel').removeClass(function (index, cls) {
            var re = mask.replace(/\*/g, '\\S+');
            return (cls.match(new RegExp('\\b' + re + '', 'g')) || []).join(' ');
        });
        $('#flaglabel').addClass("flag-icon-squared")
        const phoneNumber = parsePhoneNumber(event.target.value, 'RU')
        if (phoneNumber && phoneNumber.country) {
            $('#flaglabel').addClass("flag-icon-" + phoneNumber.country.toLowerCase())
        }
        this.getState((curState) => {
            let data = curState.data;
            data.phone=event.target.value;
            this.setState({data: data});
        })
    }
    renderMain() {
        const { isLoading } = this.state;
        if (isLoading) {
            return null;
        }
        return (
            <div className='container'>
                <div className="modal recommendation_saved h-100 d-flex align-items-center justify-content-center d-none">
                    <div className="modal-dialog modal-lg modal-dialog-centered d-flex justify-content-center">
                        <div className="modal-content">
                            <div className="rounded-4 bg-white p-4 d-flex">
                                <img className="img" src="/images/published.png" alt={""}/>
                                <div className="m-3">
                                    <h4>Saved</h4>
                                    <small>
                                        The recommendation was successfully saved.
                                        <br/>You need to publish it so others can find it.
                                    </small>
                                </div>
                            </div>
                            <div className="mt-3 flex-row-reverse d-flex  align-items-center">
                                <a href="/recommendation/create" className="send btn btn-primary rounded-pill p-2 m-2">Create Recommendation <img src="/images/plus.png" alt={""}/></a>
                                <a href="/profile#recommendations" className="btn btn-dark rounded-pill p-3 m-2">Back to Profile</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal recommendation_published h-100 d-flex align-items-center justify-content-center d-none">
                    <div className="modal-dialog modal-lg modal-dialog-centered d-flex justify-content-center">
                        <div className="modal-content">
                            <div className="rounded-4 bg-white p-4 d-flex">
                                <img className="img" src="/images/published.png" alt={""}/>
                                <div className="m-3">
                                    <h4>Published</h4>
                                    <small>
                                        You will receive notifications when someone try <br/>to contact talent via your recommendation.
                                    </small>
                                </div>
                            </div>
                            <div className="mt-3 flex-row-reverse d-flex  align-items-center">
                                <a href="/recommendation/create" className="send btn btn-primary rounded-pill p-2 m-2">Create Recommendation <img src="/images/plus.png" alt={""}/></a>
                                <a href="/profile#recommendations" className="btn btn-dark rounded-pill p-3 m-2">Back to Profile</a>
                            </div>
                        </div>
                    </div>
                </div>
                <form className="create_recommendation ">
                    <div className="row text-center mb-5"><h2>RECOMMENDATION</h2></div>
                    <div className="row g-2 align-items-center">
                        <div className="col-md-3">
                            <AvatarEditor avatar={this.state.avatar} onSelect={(val) => { $('.ava-preview').attr("data",val).attr("changed","true"); this.setState({["enableSave"]:true}) }}/>
                        </div>
                        <div className="col-md-9">
                            <div className="input-group mb-3" onClick={(e)=>{this.focusElement(e)}}>
                                <label className="input-group-text p-4 rounded-pill-left" htmlFor="inputGroupFile01">First Name</label>
                                <input type="text" className="form-control text-muted p-4 rounded-pill-right" placeholder="Fill"
                                       value={this.state.data.firstName}
                                       onChange={(val) => {this.getState((curState) => {
                                           let data = curState.data;
                                           data.firstName=val.target.value;
                                           this.setState({data: data});
                                       })}}/>
                            </div>
                            <div className="input-group mb-3" onClick={(e)=>{this.focusElement(e)}}>
                                <label className="input-group-text p-4 rounded-pill-left" htmlFor="inputGroupFile01">Last Name</label>
                                <input type="text" className="form-control  text-muted  p-4 rounded-pill-right" placeholder="Fill"
                                       value={this.state.data.lastName}
                                       onChange={(val) => {this.getState((curState) => {
                                           let data = curState.data;
                                           data.lastName=val.target.value;
                                           this.setState({data: data});
                                       })}}/>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12">
                            <textarea className="form-control form-rounded p-4 rounded-5 text-secondary" placeholder="Type recommendation..."
                                      value={this.state.data.text}
                                      onChange={(val) => {this.getState((curState) => {
                                          let data = curState.data;
                                          data.text=val.target.value;
                                          this.setState({data: data});
                                      })}}>
                            </textarea>
                        </div>
                    </div>
                    <div className="row mt-3 g-2 align-items-center">
                        <div className="col-md-6">
                            <div className="input-group mb-3 " onClick={(e)=>{this.focusElement(e)}}>
                                <label className="input-group-text p-4 rounded-pill-left">Age</label>
                                <input type="text" inputMode="numeric" pattern="[0-9]*"  value={this.state.data.age} className="form-control text-muted text-end p-4 "
                                       onChange={(val) => {this.getState((curState) => {
                                           let data = curState.data;
                                           data.age=val.target.value;
                                           this.setState({data: data});
                                       })}}/>
                                <label className="input-group-text p-4 rounded-pill-right">years old</label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-group mb-3">
                                <label className="input-group-text p-4 rounded-pill-left">Sex</label>
                                <select className="form-select form-select text-secondary rounded-pill-right"
                                        aria-label=".form-select-sm example" defaultValue={this.state.data.gender}
                                        onChange={(e) => {
                                            this.getState((curState) => {
                                                let data = curState.data;
                                                data.gender=e.target.value;
                                                this.setState({data: data});
                                            })
                                        }}>
                                    <option value="">Select</option>
                                    <option value="0">{GENDERS[0]}</option>
                                    <option value="1">{GENDERS[1]}</option>
                                </select>
                            </div>
                        </div>
                        <div className="row mt-3 g-2 align-items-center">
                            <div className="col-md-6">
                                <div className="input-group mb-3 " onClick={(e)=>{this.focusElement(e)}}>
                                    <label className="input-group-text p-4 rounded-pill-left">Experience</label>
                                    <input type="text" inputMode="numeric" pattern="[0-9]*"  className="form-control text-muted text-end p-4 " placeholder=""
                                           value={this.state.data.experience}
                                           onChange={(val) => {this.getState((curState) => {
                                               let data = curState.data;
                                               data.experience=val.target.value;
                                               this.setState({data: data});
                                           })}}/>
                                    <label className="input-group-text p-4 pe-5 rounded-pill-right">years</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="input-group mb-3">
                                    <label className="input-group-text p-4 rounded-pill-left">Type of employment</label>
                                    <select className="form-select form-select text-secondary rounded-pill-right"
                                            aria-label=".form-select-sm example" defaultValue={this.state.data.employmentType}
                                            onChange={(e) => {
                                                this.getState((curState) => {
                                                    let data = curState.data;
                                                    data.employmentType=e.target.value;
                                                    this.setState({data: data});
                                                })
                                            }}>
                                        <option value="0">{EMPLOYMENT[0]}</option>
                                        <option value="1">{EMPLOYMENT[1]}</option>
                                        <option value="2">{EMPLOYMENT[2]}</option>
                                        <option value="3">{EMPLOYMENT[3]}</option>
                                        <option value="4">{EMPLOYMENT[4]}</option>
                                        <option value="5">{EMPLOYMENT[5]}</option>
                                        <option value="6">{EMPLOYMENT[6]}</option>
                                        <option value="7">{EMPLOYMENT[7]}</option>
                                        <option value="8">{EMPLOYMENT[8]}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row g-2 align-items-center">
                                <div className="col-md-6">
                                    <div className="input-group mb-3">
                                        <label className="input-group-text p-4 rounded-pill-left">Relocate</label>
                                        <select className="form-select form-select text-secondary rounded-pill-right"
                                                aria-label=".form-select-sm example" defaultValue={this.state.data.relocation}
                                                onChange={(e) => {
                                                    this.getState((curState) => {
                                                        let data = curState.data;
                                                        data.relocation=e.target.value;
                                                        this.setState({data: data});
                                                    })
                                                }}>
                                            <option value="">Select</option>
                                            <option value="0">{RELOCATION[0]}</option>
                                            <option value="1">{RELOCATION[1]}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                        <InputGroup className={"mb-3 d-flex flex-nowrap"}>
                                            <InputGroup.Text className="p-4 rounded-pill-left">Location</InputGroup.Text>
                                            <LocationFinder className={"text-muted text-end"}
                                                            defaultInputValue={this.state.data.location ? this.state.data.location : ""}
                                                            onChange={(features) => {
                                                                //console.log("onChange",features)
                                                                let feature = features[0]
                                                                let location=""
                                                                if(feature)
                                                                    location=feature.properties.city+", "+feature.properties.country;
                                                                this.getState((curState) => {
                                                                    let data = curState.data;
                                                                    data.location=location;
                                                                    this.setState({data: data});
                                                                })
                                                            }}
                                            />
                                            <InputGroup.Text className="input-group-text p-4 pe-5 rounded-pill-right">Type or find</InputGroup.Text>
                                        </InputGroup>
                                </div>
                            </div>
                            <div className="row g-2 align-items-center">
                                <div className="col-md-6">
                                    <InputGroup className={"mb-3 d-flex flex-nowrap"}>
                                        <InputGroup.Text className="p-4 rounded-pill-left">Phone Number
                                            <span id="flaglabel"
                                                  className="ms-2 ps-2 pe-2 border align-self-center rounded-circle flag-icon flag-icon-squared"></span>
                                        </InputGroup.Text>
                                        <input id="phone_number" inputMode="numeric" type="text" className="form-control text-muted text-end p-4 rounded-pill-right"
                                               placeholder="7 000 000 00 00" aria-label="Phone Number" aria-describedby="flaglabel"
                                               value={this.state.data.phone}
                                               onChange={this.handlePhoneChange}/>
                                    </InputGroup>

                                </div>
                                <div className="col-md-6">
                                    <InputGroup className={"mb-3 d-flex flex-nowrap"}>
                                        <InputGroup.Text className="p-4 rounded-pill-left">E-mail</InputGroup.Text>
                                        <input id="email"  type="text" className="form-control text-muted text-end p-4 rounded-pill-right"
                                               placeholder="" aria-label="Email" aria-describedby="flaglabel"
                                               value={this.state.data.email}
                                               onChange={(val) => {this.getState((curState) => {
                                                   let data = curState.data;
                                                   data.email=val.target.value;
                                                   this.setState({data: data});
                                               })}}
                                               />
                                    </InputGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12">
                            <h1>Characteristics</h1>
                        </div>
                    </div>
                    <div className="characteristics">
                        <div className="row mt-3">
                            <div className="col-12">
                                <div className="d-flex bg-white p-4 rounded-5 rounded-bottom border-bottom justify-content-between">
                                <span className="label">
                                    Creativity
                                </span>
                                    <div className="star-select">
                                        <span className="fs-5 pe-3 align-middle">{this.state.data.ratings[0].ratingValue}</span>
                                        <Rating
                                            initialRating={this.state.data.ratings[0].ratingValue}
                                            fractions={2}
                                            emptySymbol={<RatingStarEmpty/>}
                                            fullSymbol={<RatingStarFull />}
                                            onChange={(val) => {this.getState((curState) => {
                                                let data = curState.data;
                                                data.ratings[0].ratingValue=val;
                                                this.setState({data: data});
                                            })}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex bg-white p-4  border-bottom justify-content-between">
                                <span className="label">
                                    Accuracy
                                </span>
                                    <div className="star-select">
                                        <span className="fs-5 pe-3 align-middle">{this.state.data.ratings[1].ratingValue}</span>
                                        <Rating
                                            initialRating={this.state.data.ratings[1].ratingValue}
                                            fractions={2}
                                            emptySymbol={<RatingStarEmpty/>}
                                            fullSymbol={<RatingStarFull />}
                                            onChange={(val) => {this.getState((curState) => {
                                                let data = curState.data;
                                                data.ratings[1].ratingValue=val;
                                                this.setState({data: data});
                                            })}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex bg-white p-4  border-bottom justify-content-between">
                                <span className="label">
                                    Punctuality
                                </span>
                                    <div className="star-select">
                                        <span className="fs-5 pe-3 align-middle">{this.state.data.ratings[2].ratingValue}</span>
                                        <Rating
                                            initialRating={this.state.data.ratings[2].ratingValue}
                                            fractions={2}
                                            emptySymbol={<RatingStarEmpty/>}
                                            fullSymbol={<RatingStarFull />}
                                            onChange={(val) => {this.getState((curState) => {
                                                let data = curState.data;
                                                data.ratings[2].ratingValue=val;
                                                this.setState({data: data});
                                            })}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex bg-white p-4 rounded-5 rounded-top justify-content-between">
                                <span className="label">
                                    Responsibility
                                </span>
                                    <div className="star-select">
                                        <span className="fs-5 pe-3 align-middle">{this.state.data.ratings[3].ratingValue}</span>
                                        <Rating
                                            initialRating={this.state.data.ratings[3].ratingValue}
                                            fractions={2}
                                            emptySymbol={<RatingStarEmpty/>}
                                            fullSymbol={<RatingStarFull />}
                                            onChange={(val) => {this.getState((curState) => {
                                                let data = curState.data;
                                                data.ratings[3].ratingValue=val;
                                                this.setState({data: data});
                                            })}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-6 d-inline-flex align-items-center">
                            {this.state.id?
                                <button className="btn btn-dark rounded-circle d-inline-block float-end p-4 me-3"
                                        type="button"><img src="/images/trashbin.png" alt={""}
                                onClick={()=>{this.onDelete()}}/></button>
                                : <div/>}
                            <div className="input-group onClick={(e)=>{this.focusElement(e)}}">
                                <label className="input-group-text p-4 rounded-pill-left" htmlFor="inputGroupFile01">Set price, $</label>
                                <input type="text" inputMode="numeric" pattern="[0-9]*" className="form-control text-muted  text-end p-4 rounded-pill-right"
                                       value={this.state.data.price}
                                       onChange={(val) => {this.getState((curState) => {
                                           let data = curState.data;
                                           data.price=val.target.value;
                                           this.setState({data: data});
                                       })}} placeholder="0"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className={"w-100 d-flex flex-row"}>
                                <a href="#" className="save w-50 btn btn-secondary2 rounded-pill p-4 ps-5 pe-5 me-5" onClick={this.onSave}>Save</a>
                                <a href="#" className="publish w-50 btn btn-primary rounded-pill p-4  " onClick={this.onPublish}>Publish <img
                                    src="/images/arrow2.png" alt={""}/></a>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <div className="error d-none alert alert-danger" role="alert">
                                Error
                            </div>
                        </div>
                    </div>
                    <div className="about pt-3">
                        <Footer1/>
                    </div>
                </form>
            </div>
        );
    }
}

export default CreateRecommendation;