import React from 'react'
import withRouter from '../util/withRouter'
import ViewRecommendation from "./ViewRecommendation";
import ContactTalent from "./ContactTalent";


class ContactTalentWithRouter extends ContactTalent {

}

export default withRouter(ContactTalentWithRouter);