import React from 'react'
import Page from './Page.js'
import FindTalents from "./FindTalents";
import Search from "../util/Search";
import Backend from "../util/Backend";
import UserBadge from "../widgets/UserBadge";
import $ from "jquery";
class ContactTalent extends Page {
    id;
    state;
    constructor(props) {
        super(props);
        this.id= this.props.params.id
        this.state = {recommendation: {}, isLoading: true};
    }
    componentDidMount() {
        let params = {}
        Backend.do("GET", "/recommendations/"+this.id, params,  (response) => {
            if(response.error || response.errors ){
               /* console.log(response.errors ? response.errors : response.error)*/
                this.setState({recommendation: {}})
            }else{
                console.log("Got recommendation",response)
                this.setState({recommendation: response})
            }
            this.setState({isLoading:false})
        })
    }
    onSend = async () => {
        let self = this;
        let params = {
            hunterId: this.user.id,
            jobId: 0,
            recommendationId: this.state.recommendation.id,
            recommenderId: this.state.recommendation.recommender.id,
            message: this.state.message
        }
        await Backend.do("POST","/messages/contact/", params, function (response) {
            //console.log(response);
            let recommendation = response;
            if (response.error || response.errors) {
                $('.create_recommendation .error').removeClass('d-none').text(response.errors ? response.errors : response.error)
            } else {
                $('.message_sent').removeClass('d-none')
                $('.contact-talent').addClass('d-none')
                $('.create_recommendation .error').addClass('d-none')
            }
        })
    }
    renderMain() {
        if (this.state.isLoading) {
            return (
                    <div>
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
            );
        }else{
            var r = this.state.recommendation
            return (
                <div>
                    <div className="modal message_sent h-100 d-flex align-items-center justify-content-center d-none">
                        <div className="modal-dialog modal-lg modal-dialog-centered d-flex justify-content-center">
                            <div className="modal-content">
                                <div className="rounded-4 bg-white p-4 d-flex">
                                    <img className="img" src="/images/published.png" alt={""}/>
                                    <div className="m-3">
                                        <h4>Message Sent</h4>
                                        <small>
                                            You will receive notifications when someone try <br/>to contact you.
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"container contact-talent ps-4 pe-4"}>
                        <div className={"row pt-1"}>
                            <UserBadge id={r.recommender.id} name={r.recommender.firstName+" "+r.recommender.lastName} big_title={true} avatar={"/api/users/"+r.recommender.id+"/avatar/image"}/>
                        </div>
                        <div className={"row"}>
                        <textarea className="form-control form-rounded p-4 rounded-5 text-secondary" placeholder="Your message..."
                                  value={this.state.message}
                                  onChange={(val) => {this.setState({message: val.target.value})}}>
                        </textarea>
                        </div>
                        <div className={"row"}>
                            <div className={"col-12 d-flex justify-content-end"}>
                                <a href="#" className="send mt-3 btn btn-primary rounded-pill p-2 ps-3 pe-3 d-flex justify-content-between" onClick={this.onSend}>Send <img
                                    src="/images/arrow2.png"/></a>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12">
                                <div className="error d-none alert alert-danger" role="alert">
                                    Error
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            )
        }

    }
}
export default ContactTalent;