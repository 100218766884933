import React from 'react'
import CreateRecommendation from "./CreateRecommendation";
import Recommendation from "../widgets/Recommendation";
import CreateJob from "./CreateJob";
import Job from "../widgets/Job";
class ViewJob extends CreateJob {
    renderMain() {
        const { isLoading } = this.state;
        if (isLoading) {
            return null;
        }
        return (
            <div className={"ps-md-4 pe-md-4"}>
                <Job json={this.state.data} />
            </div>
        );
    }
}

export default ViewJob;