// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all and (min-height: 720px) {
.home-title {
    top: 130px;
    width: 100%;
    font-weight: 800;
    font-size: 72px;
    line-height: 111%;
    }
   .face1-container, .face2-container , .face3-container  {
       width: 428px;
       height: 479px;
       bottom: -136px;
   }
     .arrow-container .align-items-end {
         margin-left: 0;
    }
    .find-container {
        bottom: 152px;
    }
   .about .faces-container img{
       width: 1883px;
   }
}
`, "",{"version":3,"sources":["webpack://./src/scss/media_height_720.css"],"names":[],"mappings":"AAAA;AACA;IACI,UAAU;IACV,WAAW;IACX,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB;GACD;OACI,YAAY;OACZ,aAAa;OACb,cAAc;GAClB;KACE;SACI,cAAc;IACnB;IACA;QACI,aAAa;IACjB;GACD;OACI,aAAa;GACjB;AACH","sourcesContent":["@media all and (min-height: 720px) {\n.home-title {\n    top: 130px;\n    width: 100%;\n    font-weight: 800;\n    font-size: 72px;\n    line-height: 111%;\n    }\n   .face1-container, .face2-container , .face3-container  {\n       width: 428px;\n       height: 479px;\n       bottom: -136px;\n   }\n     .arrow-container .align-items-end {\n         margin-left: 0;\n    }\n    .find-container {\n        bottom: 152px;\n    }\n   .about .faces-container img{\n       width: 1883px;\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
