import React from 'react';
import $ from 'jquery';

class BalanceShort extends React.Component {
    amount;
    constructor(props) {
        super(props);
        this.amount = props.amount;
    }
    handleClick(){
        $('.profile-nav .nav-link').removeClass('active');
        $('.profile .profile-section').addClass('d-none');
        $('.profile .profile-section.balance').removeClass('d-none')
    }
    render() {
        return (
            <div className="balance rounded-4 p-3 m-3">
                <span className="amount">
                    $ {this.amount}
                </span>
                <div className="mt-3 mb-3">
                    <span className="label">
                    Balance
                    </span>
                    <button className="btn btn-dark rounded-circle d-inline-block float-end ps-2 pe-2" type="button" onClick={this.handleClick}><img src="./images/arrow2.png"/></button>
                </div>
            </div>
        )
    }
}
export default BalanceShort;