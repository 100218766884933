import React from 'react';
import $ from 'jquery';

class ProfileNotifications extends React.Component {
    render() {
        return (
            <div className="profile-section notifications d-none">
                <div className="header mb-4">
                    Notifications
                </div>
                <div className="profile-content rounded-5">
                    <div className="row">
                        <div className="col-12">
                            <div className="subscribe rounded-4 p-4 d-md-flex justify-content-between align-items-center mb-3">
                                        <span className="d-inline-block align-middle">
                                            Enter your e-mail to recieve updates via e-mail
                                        </span>
                                        <div className="inputs d-flex ">
                                            <input type="text" className="form-control rounded-pill w-100" placeholder="email" aria-label="email"/>
                                            <button className="btn btn-secondary2 rounded-circle d-inline-block float-end ms-2 ps-2 pe-2" type="button">
                                                <img src="./images/arrow2.png"/>
                                            </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="day-title mt-4 mb-2">
                                11 mar 2023
                            </div>
                            <div className="day-content bg-white rounded-4 p-4">
                                <div className="msg mb-2">
                                    Jack want to send a message to <span className="text-primary">Mary</span>
                                    <hr/>
                                </div>

                                <div className="msg mb-2">
                                    <span className="text-primary">Mary</span> card revied 57 times
                                    <hr/>
                                </div>
                                <div className="msg mb-2">
                                    <span className="text-primary">Paul</span> card revied 57 times
                                </div>
                            </div>
                            <div className="day-title mt-4 mb-2">
                                10 mar 2023
                            </div>
                            <div className="day-content bg-white rounded-4 p-4">
                                <div className="msg mb-2">
                                    Jack want to send a message to <span className="text-primary">Mary</span>
                                    <hr/>
                                </div>

                                <div className="msg mb-2">
                                    <span className="text-primary">Mary</span> card revied 57 times
                                    <hr/>
                                </div>
                                <div className="msg mb-2">
                                    <span className="text-primary">Paul</span> card revied 57 times
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ProfileNotifications;