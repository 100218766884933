import React from "react";
export const Gauge = ({ percent = 0, radius, strokeWidth=radius * 0.2, ...rest }) => {
    const innerRadius = radius - strokeWidth;
    const circumference = innerRadius * 2 * Math.PI;
    const arc = circumference ;
    const dashArray = `${arc} ${arc}`;
    const transform = `rotate(-82, ${radius}, ${radius})`;
    const transform2 = `rotate(-95, ${radius}, ${radius})`;
    let offset = circumference;
    let offset2 = 0
    if(percent>0){
        if(percent<100){
            offset = arc - (percent / 100) * arc +radius/4.5;
            offset2 = - (percent / 100) * arc -radius/4.5;
        }else{
            offset=0;
            offset2=0;
        }
    }
    const toFixedWithoutZeros = (num, precision) =>
        num.toFixed(precision).replace(/\.0+$/, '');

    const label = toFixedWithoutZeros(percent/20,1)


    return (
        <svg height={radius * 2} width={radius*2}>

     <circle className="gauge_base" cx={radius} cy={radius} fill="transparent" r={innerRadius} stroke="#C7C6C8"
        strokeDasharray={dashArray}
         strokeDashoffset={offset2}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
        transform={transform2}
        />

    
      <circle className="gauge_percent" cx={radius} cy={radius} fill="transparent" r={innerRadius} stroke="#FF855E"
        strokeDasharray={dashArray} strokeDashoffset={offset}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
        style={{
            transition: "stroke-dashoffset 0.3s",
        }}
        transform={transform}
        />

        <text id="count" x="50%" y="50%" fill="#000" textAnchor="middle" dy="7" fontSize="20">{label}</text>

        </svg>
);
};