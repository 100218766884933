import React from 'react';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import UserBadge from "./UserBadge";
import { EMPLOYMENT, RELOCATION} from '../util/Constants'
import RecommendationShort from "./RecommendationShort";
import RatingGauge from "./RatingGauge";
import RecommenderBadge from "./RecommenderBadge";
import Backend from "../util/Backend";
import $ from "jquery";
import {forEach} from "react-bootstrap/ElementChildren";

const animatedComponents = makeAnimated();
class Recommendation extends RecommendationShort {
    joblist=[];
    options=[]
    constructor(props) {
        super(props);
        let self = this
        Backend.do("GET", "/jobs/user", {}, function (response) {
            console.log(response);
            if (response.error || response.errors) {
                $('.error').removeClass('d-none').text(response.errors ? response.errors : response.error)
            } else {
                response.forEach(e=>{
                    self.joblist.push({label:e.vacancy,value:e.id})
                })
                console.log("Options",self.joblist)
            }
        })
        this.state.defaultSelection=[]
        console.log("WTF")
        let params = {number: this.index, limit: this.limit}
        Backend.do("GET", "/recommendations/"+this.id+"/jobs", params,  (response) => {
            if(response.error || response.errors ){
                //console.log(response.errors ? response.errors : response.error)
            }else{
                console.log("Got linked jobs",response)
                response.forEach(r=>{
                    this.state.defaultSelection.push({label: r.vacancy, value: r.id})
                })
            }
        },false)
        console.log("Constructor",this.state.defaultSelection)
        this.setState(this.state)
    }
    onClick= ()=>{

    }

    handleJobChange = (selectedOption) => {
        console.log("selected",selectedOption,"defaultSelection",this.state.defaultSelection)
        this.setState({ selectedOption });
        let self = this
        let deleted=[]
        this.state.defaultSelection.forEach(opt=>{
            let selected=false;
            selectedOption.forEach(job=> {
                if (opt.value == job.value)
                    selected = true;
            })
            //remove link
            if(!selected){
                deleted.push(opt)
                let params={jobId: opt.value}
                Backend.do("POST", "/recommendations/"+this.id+"/status", params, function (response) {
                    if (response.error || response.errors) {
                        $('.error').removeClass('d-none').text(response.errors ? response.errors : response.error)
                    } else {

                    }
                })
            }
            if(deleted.length>0){
                deleted.forEach(d=>{
                    self.state.defaultSelection.splice(self.state.defaultSelection.indexOf(d),1)
                })
                console.log("deleted",deleted)
                self.setState(self.state)
                console.log("selected",self.state.defaultSelection)
            }

        })

        selectedOption.forEach(job=>{
            let stored=false;
            this.state.defaultSelection.forEach(opt=>{
                if(opt.value==job.value)
                    stored=true;
            })
            //add link
            if(!stored){
                let params={jobId: job.value, status: "New"}
                Backend.do("POST", "/recommendations/"+self.id+"/status", params, function (response) {
                    if (response.error || response.errors) {
                        $('.error').removeClass('d-none').text(response.errors ? response.errors : response.error)
                    } else {
                        self.state.defaultSelection.push({label:job.label,value:job.value})
                        self.setState(self.state)
                        console.log("selection",self.state.defaultSelection)
                    }
                })
            }

        })
    }

     filterJobs = (inputValue) => {
        return this.joblist.filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    render() {
        return (
            <div>
            <div className={"row"}>
                <div className={"col-lg-3 d-flex justify-content-center"}>
                    <img src={this.avatar} alt="" className="d-none d-lg-block avatar-big align-top"/>
                </div>
                <div className={"col col-lg-6"}>
                    <div className="card recommendation bg-white p-4" onClick={() => {this.onClick()}}>
                        <div className="card-body">
                            <div className="card-title d-block ">
                                <div className="user-badge d-inline-flex w-100 d-flex align-items-center">
                                    <img src={this.avatar} className="avatar align-middle d-block d-lg-none" alt={"avatar"}/>
                                    <div className={"user-info"}>
                                        <div className="user-name">{this.name}</div>
                                        <div className={"mt-2 age"+ (this.avatar? "" : " mt-3 mb-3")}>
                                            {this.age}
                                        </div>
                                        {this.price>0 &&
                                            <div className={"mt-1 price"+ (this.avatar? "" : " mt-3 mb-3")}>
                                                $ {this.price}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <p className="card-text">
                                {this.text}
                            </p>
                        </div>
                        <div className={"row pt-3 ps-3"}>
                            <div className={"col-6"}>
                                <div className=" mb-3">
                                    <small>Experience</small>
                                    <input type="email" readOnly className="form-control-plaintext mb-5"
                                           value={this.experience+" years"}/>
                                    <small>Location</small>
                                    <input  readOnly className="form-control-plaintext pt-2"
                                           value={this.location}/>

                                </div>
                            </div>
                            <div className={"col-6"}>
                                <small>Type of employment</small>
                                <input type="email" readOnly className="form-control-plaintext mb-5"
                                       value={EMPLOYMENT[this.employmentType]}/>
                                <small>Relocate</small>
                                <input type="email" readOnly className="form-control-plaintext mb-5"
                                       value={RELOCATION[this.relocation]}/>
                            </div>
                        </div>
                        <div className={"d-flex justify-content-around"}>
                            <RatingGauge label={this.ratings[0].ratingType.name} percent={this.ratings[0].ratingValue*20}/>
                            <RatingGauge label={this.ratings[1].ratingType.name} percent={this.ratings[1].ratingValue*20}/>
                            <RatingGauge label={this.ratings[2].ratingType.name} percent={this.ratings[2].ratingValue*20}/>
                            <RatingGauge label={this.ratings[3].ratingType.name} percent={this.ratings[3].ratingValue*20}/>
                        </div>

                    </div>
                </div>
                <div className={"col-md-3 col-lg-3 mt-3 m-md-3 m-lg-0"}>
                    <a href={"/contact/talent/"+this.id} className="publish w-100 btn btn-primary rounded-pill p-4 d-flex justify-content-between mb-3" >Contact <img
                        src="/images/arrow2.png" alt={"contact"}/></a>
                    <div className={"bg-white p-3 rounded-4 mb-3"}>
                        <span className={""}>Link to my job:</span>
                        <div className={"mt-2"}>
                            <ReactSelect
                                name="form-field-name"
                                /*cacheOptions
                                defaultOptions*/
                                defaultValue={this.state.defaultSelection}
                                onChange={this.handleJobChange}
                                isMulti
                                options={this.joblist}
                                components={animatedComponents}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 10,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#FCA187FF',
                                        primary: '#FF855E',
                                    },
                                })
                                }
                            />
                        </div>
                    </div>
                    <div className={"bg-white p-3 rounded-4 d-lg-block d-none"}>
                        <RecommenderBadge json={this.data.recommender} recommendation={this.data.id} />
                    </div>
                </div>

            </div>
                <div className={"row d-lg-none d-block"}>
                    <div className={"col-12 col-md-6"}>
                        <div className={"bg-white m-1 mt-md-3 p-3 rounded-4 "}>
                            <RecommenderBadge json={this.data.recommender} recommendation={this.data.id}  />
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
export default Recommendation;