import React from 'react'
import withRouter from '../util/withRouter'
import ViewRecommendation from "./ViewRecommendation";
import ViewJob from "./ViewJob";


class ViewJobWithRouter extends ViewJob {

}

export default withRouter(ViewJobWithRouter);