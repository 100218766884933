import React from 'react'
import CreateRecommendation from "./CreateRecommendation";
import Recommendation from "../widgets/Recommendation";
class ViewRecommendation extends CreateRecommendation {
    renderMain() {
        const { isLoading } = this.state;
        if (isLoading) {
            return null;
        }
        return (
            <div className={"ps-md-4 pe-md-4"}>
                <Recommendation json={this.state.data} />
            </div>
        );
    }
}

export default ViewRecommendation;