// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all and (min-width: 390px) {
.home-title {
    width: 100%;
    font-weight: 800;
    font-size: 72px;
    line-height: 111%;
    }
    .arrow-container .left-arrow, .arrow-container .mid-arrow, .arrow-container .right-arrow{
    opacity: 0;
    }
     .arrow-container .align-items-end {
        margin-left: -10px;
     }
     .find-container {
             display: none;
         }
}

`, "",{"version":3,"sources":["webpack://./src/scss/media_width_390.css"],"names":[],"mappings":"AAAA;AACA;IACI,WAAW;IACX,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB;IACA;IACA,UAAU;IACV;KACC;QACG,kBAAkB;KACrB;KACA;aACQ,aAAa;SACjB;AACT","sourcesContent":["@media all and (min-width: 390px) {\n.home-title {\n    width: 100%;\n    font-weight: 800;\n    font-size: 72px;\n    line-height: 111%;\n    }\n    .arrow-container .left-arrow, .arrow-container .mid-arrow, .arrow-container .right-arrow{\n    opacity: 0;\n    }\n     .arrow-container .align-items-end {\n        margin-left: -10px;\n     }\n     .find-container {\n             display: none;\n         }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
