import React, {useState, useEffect, useRef, useLayoutEffect} from 'react';
import $ from 'jquery';
import Page from './Page.js'
import UserBadge from '../widgets/UserBadge.js'
import BalanceShort from '../widgets/BalanceShort.js'
import ProfileSettings from "./profile/ProfileSettings";
import ProfileNotifications from "./profile/ProfileNotifications";
import ProfileRecommendations from "./profile/ProfileRecommendations";
import ProfileSubscription from "./profile/ProfileSubscription";
import ProfileBalance from "./profile/ProfileBalance";
import ProfileJobs from "./profile/ProfileJobs";

class Profile extends Page {
    constructor(props) {
        super(props);
        this.state={}
        if(!this.user || !this.user.id)
            window.location.replace('/auth')
        this.handleLoad = this.handleLoad.bind(this);
        //console.log(this.user)
    }

    componentDidMount() {
        window.addEventListener('load', this.handleLoad);
    }

    componentWillUnmount() {
        window.removeEventListener('load', this.handleLoad)
    }

    handleLoad() {
        $('.profile-nav .nav-link').click(function (e) {
            $('.profile-nav button[data-bs-toggle="collapse"]').not('.collapsed').click();
            $('.profile-nav .nav-link').removeClass('active')
            $(this).addClass('active')
            $('.profile .profile-section').addClass('d-none');
            $('.profile .profile-section.' + $(this).attr('section')).removeClass('d-none');
            //$('.navbar-small .btn[data-bs-toggle="collapse"]').click();
        });
        if(window.location.hash){
            $($('.profile-nav .nav-item a.nav-link[href="'+window.location.hash+'"]')[0]).addClass('active');
            $($('.profile-nav .nav-item a.nav-link[href="'+window.location.hash+'"]')[0]).click()
        }
    }

    updateMenu() {

    }

    renderNavs() {
        return (
            <div className="nav-container bg-white rounded-5 pb-3">
                <nav className="nav flex-column pt-4">
                    <li className="nav-item">
                        <a className="nav-link" aria-current="page" href="#jobs" section="jobs">My jobs</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link " aria-current="page" href="#recommendations" section="recommendations">My
                            recommendations</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#subscription" section="subscription">Subscription</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#notifications" section="notifications">Notifications</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link active" href="#settings" section="settings">Settings</a>
                    </li>
                </nav>
                <BalanceShort amount="24,3"/>
            </div>
        )
    }

    renderMainMenuProfile() {
        const logout = (e) => {
            sessionStorage.clear();
            window.location.reload(false);
        }
        return (<a href="#" onClick={logout} className="logout btn btn-dark rounded p-3">log out</a>)
    }

    renderProfileSectionContent() {
        return (
            <div>
                <ProfileJobs/>
                <ProfileRecommendations/>
                <ProfileSettings/>
                <ProfileNotifications/>
                <ProfileSubscription/>
                <ProfileBalance/>
            </div>
        );
    }

    renderMain() {
        return (
            <div className="profile">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3 d-lg-block d-none ">
                            <div className="profile-nav rounded-5 p-3">
                                <UserBadge id={this.user.id} name={this.user.fullName}  avatar={this.user.avatar ? this.user.avatar : "/api/users/"+this.user.id+"/avatar/image"} />
                                {this.renderNavs()}
                            </div>
                        </div>
                        <div className="col-12 col-lg-9">
                            <div className="d-lg-none  profile-nav navbar-small rounded-5  mb-3">
                                <div className="bg-white rounded-5 user-badge-container p-3">
                                    <div className={"row"}>
                                        <div className={"col-10"}>
                                            <UserBadge id={this.user.id} name={this.user.fullName}  avatar={this.user.avatar ? this.user.avatar : "/api/users/"+this.user.id+"/avatar/image"} />
                                        </div>
                                        <div className={"col-2"}>
                                            <button
                                                className="btn btn-grey rounded-circle d-inline-block float-end ps-2 pe-2 collapse collapsed"
                                                data-bs-toggle="collapse" href={"#nav-collapse"} role="button"
                                                aria-expanded="true" aria-controls={"nav-collapse"}
                                                type="button">
                                                <img className="if-collapsed" src="../images/menu.png"/>
                                                <img className="if-not-collapsed" src="../images/x.png"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="collapse bg-white rounded-3 mt-3" id="nav-collapse">
                                    {this.renderNavs()}
                                </div>
                            </div>
                            {this.renderProfileSectionContent()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Profile;