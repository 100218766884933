import React from 'react';
import JobShort from "./JobShort";
import {Gauge} from "./gauge";
class RatingGauge extends React.Component {
    percent;
    label;

    constructor(props, context) {
        super(props, context);
        this.percent= this.props.percent;
        this.label=this.props.label;
    }

    render() {
        return (<div className={"rating-gauge"}>
            <Gauge radius={40} percent={this.percent} strokeWidth={5}/>
            <div className={"label mt-3"}>
                {this.label}
            </div>
        </div>)
    }
}

export default RatingGauge;
