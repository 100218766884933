// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all and (min-height: 200px) {
.home-title {
    top: 30px;
    width: 100%;
    font-weight: 800;
    font-size: 52px;
    line-height: 111%;
    }
   .face1-container, .face2-container , .face3-container  {
       width: 178px;
       height: 200px;
       bottom: 5px;
   }
    .arrow-container {
        bottom: 286px;
        margin-bottom: -98px;
    }
    .find-container {
        bottom: 83px;
    }
    .about .faces-container img{
            width: 700px;
    }
  .about .faceless .title {
    margin-bottom: 20px;
  }
}


`, "",{"version":3,"sources":["webpack://./src/scss/media_height_200.css"],"names":[],"mappings":"AAAA;AACA;IACI,SAAS;IACT,WAAW;IACX,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB;GACD;OACI,YAAY;OACZ,aAAa;OACb,WAAW;GACf;IACC;QACI,aAAa;QACb,oBAAoB;IACxB;IACA;QACI,YAAY;IAChB;IACA;YACQ,YAAY;IACpB;EACF;IACE,mBAAmB;EACrB;AACF","sourcesContent":["@media all and (min-height: 200px) {\n.home-title {\n    top: 30px;\n    width: 100%;\n    font-weight: 800;\n    font-size: 52px;\n    line-height: 111%;\n    }\n   .face1-container, .face2-container , .face3-container  {\n       width: 178px;\n       height: 200px;\n       bottom: 5px;\n   }\n    .arrow-container {\n        bottom: 286px;\n        margin-bottom: -98px;\n    }\n    .find-container {\n        bottom: 83px;\n    }\n    .about .faces-container img{\n            width: 700px;\n    }\n  .about .faceless .title {\n    margin-bottom: 20px;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
