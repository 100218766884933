import React from 'react';
import $ from 'jquery';

class ProfileBalance extends React.Component {
    render() {
        return (
            <div className="profile-section balance d-none">
                <div className="header mb-4">
                    Balance
                </div>
                <div className="profile-content rounded-5 p-4 ">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-between mb-3 align-items-center">
                            <div className="amount">$ 24,3</div>
                            <div className="">
                                <a href="#" className="send btn btn-dark rounded-pill ps-3 pe-3">Send <img src="./images/arrow2.png"/></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="day-title mt-4 mb-2">
                            11 mar 2023
                        </div>
                        <div className="day-content bg-white rounded-4 p-4">
                            <div className="msg mb-2 d-flex justify-content-between">
                                Succsesfull Recommendation
                                <div className="amount">
                                    $ 24
                                </div>

                            </div>
                            <hr/>
                            <div className="msg mb-2 d-flex justify-content-between">
                                Succsesfull Recommendation
                                <div className="amount">
                                    $ 24
                                </div>

                            </div>
                            <hr/>
                            <div className="msg mb-2 d-flex justify-content-between">
                                Succsesfull Recommendation
                                <div className="amount">
                                    $ 24
                                </div>

                            </div>
                        </div>
                        <div className="day-title mt-4 mb-2">
                            10 mar 2023
                        </div>
                        <div className="day-content bg-white rounded-4 p-4">
                            <div className="msg mb-2 d-flex justify-content-between">
                                Succsesfull Recommendation
                                <div className="amount">
                                    $ 24
                                </div>

                            </div>
                            <hr/>
                            <div className="msg mb-2 d-flex justify-content-between">
                                Succsesfull Recommendation
                                <div className="amount">
                                    $ 24
                                </div>

                            </div>
                            <hr/>
                            <div className="msg mb-2 d-flex justify-content-between">
                                Succsesfull Recommendation
                                <div className="amount">
                                    $ 24
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ProfileBalance;