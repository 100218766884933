import React from 'react'

class RatingStarFull extends React.Component {
    render() {
        return (
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14.2441 7.21957C15.0021 5.82969 16.9979 5.82969 17.7559 7.21957L19.2838 10.0212C19.5714 10.5486 20.0809 10.9188 20.6715 11.0294L23.8081 11.6168C25.3642 11.9082 25.9809 13.8062 24.8933 14.9566L22.7009 17.2755C22.2882 17.712 22.0936 18.311 22.1709 18.9068L22.5815 22.0715C22.7852 23.6414 21.1707 24.8145 19.7405 24.1356L16.8577 22.7671C16.3149 22.5095 15.6851 22.5095 15.1423 22.7671L12.2595 24.1356C10.8293 24.8145 9.21476 23.6414 9.41848 22.0715L9.82911 18.9068C9.90642 18.311 9.7118 17.712 9.29905 17.2755L7.10673 14.9566C6.01911 13.8062 6.63581 11.9082 8.19191 11.6168L11.3285 11.0294C11.9191 10.9188 12.4286 10.5486 12.7162 10.0212L14.2441 7.21957Z"
                    fill="#FF855E"/>
            </svg>
        );
    }
}

export default RatingStarFull;