import React, {useState, useEffect, useRef, useLayoutEffect} from 'react';
import sheet from '../style.scss';
import "../auth.css";
import 'flag-icon-css/css/flag-icons.min.css'
import $ from 'jquery';
import parsePhoneNumber from 'libphonenumber-js'

var securityRequestId;

function Home() {
    document.addEventListener("paste", function(e) {
        if (e.target.type === "text" && e.target.id !== 'phone_number') {
            var data = e.clipboardData.getData('Text').replace(/\D/g,'');
            // split clipboard text into single characters
            data = data.split('');
            // find all other text inputs
            console.log("paste",data);
            [].forEach.call(document.querySelectorAll("#verifyForm input[type=text]"), (node, index) => {
                // And set input value to the relative character
                if(data[index]){
                    node.value = data[index];
                    if(e.target===node){
                        console.log("this")
                        e.target.value=data[index]
                    }
                    console.log("node",node.value,data[index],)
                }
            });
            doVerify()
            e.preventDefault();
            return false;
        }
    });
    const doLogin = async () => {
        $('#loginForm .error').addClass('d-none')
        $('#verifyForm .error').addClass('d-none');
        $('#verifyForm .form-control').each(function () {
            $(this).removeClass('border-danger')
        })
        let phone = document.getElementById('phone_number').value;
        let params = {};
        const phoneNumber = parsePhoneNumber(phone, 'RU')
        if (phoneNumber && phoneNumber.isValid()) {
            params.phone = phoneNumber.number;
            console.log('Send phone number', phone, JSON.stringify(params))
            // Send data to the backend via POST
            const response = await fetch(
                process.env.REACT_APP_BACKEND_URL + "/public/request-phone-login-otp", {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(params)
                }
            ).then((response) => response.json());
            console.log(response);
            securityRequestId = response.uuid;
            if(response.oneTimePasswordCode){
                alert(response.oneTimePasswordCode)
            }
            showVerifyCode();
        } else {
            $('#loginForm .error').removeClass('d-none')
        }
    }
    const doVerify = async () => {
        let code = "";
        var nodes = document.getElementById('verifyForm').getElementsByTagName("input");
        for (var i = 0; i < nodes.length; i++) {
            code = code + nodes[i].value
        }
        $('#verifyForm .form-control').each(function () {
            $(this).removeClass('border-danger')
        })
        $('#verifyForm .error').addClass('d-none');
        let params = {};
        params.uuid = securityRequestId;
        params.otp = code;
        // Send data to the backend via POST
        try {
             fetch(
                process.env.REACT_APP_BACKEND_URL + "/public/verify-otp-login", {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(params)
                }
            ).then(function(resp) {
                if (resp.ok) {
                    let jsonPromise = resp.json();
                    jsonPromise.then((data) => {
                        let auth = resp.headers.get('Authorization')
                        if (auth && data.username) {
                            sessionStorage.setItem("user", JSON.stringify(data));
                            sessionStorage.setItem("authorization", auth);
                            window.location=document.referrer;
                        } else {
                            $('#verifyForm .error').removeClass('d-none');
                            $('#verifyForm .form-control').each(function () {
                                $(this).addClass('border-danger')
                            })
                            if (!auth)
                                console.err('No authorization token found.')
                        }
                    });
                }
                else{
                    $('#verifyForm .error').removeClass('d-none');
                    $('#verifyForm .form-control').each(function () {
                        $(this).addClass('border-danger')
                    })
                }
            })
        } catch (e) {

        }

    }
    const showVerifyCode = () => {
        var nodes = document.getElementById('verifyForm').getElementsByTagName("input");
        for (var i = 0; i < nodes.length; i++) {
            nodes[i].value = ''
        }
        document.getElementById("loginForm").classList.add("d-none")
        document.getElementById("verifyForm").classList.remove("d-none")
        document.getElementById("verifyForm").querySelector('.form-control').focus();
    }
    const showLogin = () => {
        document.getElementById("verifyForm").classList.add("d-none")
        document.getElementById("loginForm").classList.remove("d-none")
        document.getElementById("loginForm").querySelector('.form-control').focus();
    }
    const handlePhoneChange = (event) => {
        let mask = "flag-icon-*"
        $('#flaglabel').removeClass(function (index, cls) {
            var re = mask.replace(/\*/g, '\\S+');
            return (cls.match(new RegExp('\\b' + re + '', 'g')) || []).join(' ');
        });
        $('#flaglabel').addClass("flag-icon-squared")
        const phoneNumber = parsePhoneNumber(event.target.value, 'RU')
        if (phoneNumber && phoneNumber.country) {
            $('#flaglabel').addClass("flag-icon-" + phoneNumber.country.toLowerCase())
        }
    }
    const handleEnterPress = (event) => {
        //console.log("handleEnterPress")
        if (event.keyCode == 13)
            doLogin();
    }
    const handleKeyPress = (event) => {
        console.log("handleKeyPress",event.target.value)
        if (event.keyCode >= 48 && event.keyCode < 58) {
            event.target.value = event.key;
            if (event.target.nextElementSibling == null) {
                doVerify()
            } else {
                event.target.nextElementSibling.focus({focusVisible: true});
                console.log("Next input", event.target.nextElementSibling)
            }
        }
    }
    return (
        <div className="auth row h-100 p-lg-5">
            <div className="col-md-6 col-lg-8">
                <div className="auth-main d-none d-md-block">
                    <div className="faces">
                        <div className="il1"/>
                        <div className="il2"/>
                        <div className="il3"/>
                        <div className="il4"/>
                        <div className="il5"/>
                        <div className="il6"/>
                        <div className="il7"/>
                    </div>
                    <div className="title">real people instead of faceless resumes</div>
                </div>
            </div>
            <div className="col-md-6 col-lg-4 d-flex align-items-center">
                <div id="loginForm" className="form login w-100 p-4 m-3 m-md-0">
                    <h1 className="label pt-4">
                        Log in
                    </h1>
                    <div className="inputs [s-4">
                        <div className="btn-group">
                            <div className="d-flex flag-container">
                                <span id="flaglabel"
                                      className="border align-self-center rounded-circle flag-icon flag-icon-ru flag-icon-squared"></span>
                            </div>
                            <input id="phone_number" inputMode="numeric" type="text" className="form-control rounded-pill w-100"
                                   placeholder="7 000 000 00 00" aria-label="Phone Number" aria-describedby="flaglabel"
                                   onKeyUp={handleEnterPress} onChange={handlePhoneChange}/>
                        </div>
                        <div className="hr d-md-none"></div>
                        <span className="d-none  text-danger error">invalid phone number</span>
                        <div className="buttons pt-2">
                            <button className="btn btn-primary btn-lg rounded-pill d-none d-md-block ms-auto"
                                    type="button" onClick={doLogin}>
                                <span className="label pr-1 d-none d-md-inline-block">Next</span>
                                <img src="./images/arrow.png"/>
                            </button>
                            <button className="btn btn-primary btn-lg rounded-circle p-2 d-block ms-auto d-md-none"
                                    type="button" onClick={doLogin}>
                                <img src="./images/arrow.png"/>
                            </button>
                        </div>
                        <div className="hr d-md-none"></div>
                    </div>
                </div>
                <div id="verifyForm" className="form verify w-100 p-4 m-3 m-md-0 d-none">
                    <h1 className="label pt-4">
                        Verification code
                    </h1>
                    <div className="inputs [s-4">
                        <div className="btn-group">
                            <input id="input1" inputMode="numeric" pattern="[0-9]*" type="text" className="form-control rounded-pill" onKeyUp={handleKeyPress}/>
                            <input id="input2" inputMode="numeric" pattern="[0-9]*" type="text" className="form-control rounded-pill" onKeyUp={handleKeyPress}/>
                            <input id="input3" inputMode="numeric" pattern="[0-9]*" type="text" className="form-control rounded-pill" onKeyUp={handleKeyPress}/>
                            <input id="input4" inputMode="numeric" pattern="[0-9]*" type="text" className="form-control rounded-pill" onKeyUp={handleKeyPress}/>
                        </div>
                        <div className="hr d-md-none"></div>
                        <div className="d-none  text-danger error">The SMS code is incorrect</div>
                        <div className="buttons pt-2 d-flex align-items-center">
                            <a href="#" className="label text-secondary float-start" onClick={showLogin}>Wrong
                                number?</a>
                            <button className="btn btn-primary btn-lg rounded-pill d-none d-md-block ms-auto"
                                    type="button" onClick={doVerify}>
                                <span className="label pr-1 d-none d-md-inline-block">Next</span>
                                <img src="./images/arrow.png"/>
                            </button>
                            <button className="btn btn-primary btn-lg rounded-circle p-2 d-block ms-auto d-md-none"
                                    type="button" onClick={doVerify}>
                                <img src="./images/arrow.png"/>
                            </button>
                        </div>
                        <div className="hr d-md-none"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;