import React from 'react';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import UserBadge from "./UserBadge";
import {GENDERS, EMPLOYMENT} from '../util/Constants'
import Backend from "../util/Backend";
import $ from 'jquery';
const animatedComponents = makeAnimated();
class RecommendationShort extends React.Component {
    actions;
    active;
    views;
    viewsTrend;
    avatar;
    name;
    age;
    text;
    data;
    ratings;
    job;
    status;
    defaultStatus;
    status_options=[{label:"New",value:"New"},{label:"Screening call",value:"Screening call"},{label:"Assessment test",value:"Assessment test"},{label:"In-person interviewing",value:"In-person interviewing"},{label:"Background check",value:"Background check"},{label:"Reference checks",value:"Reference checks"},{label:"Decision and job offer",value:"Decision and job offer"}]

    constructor(props) {
        super(props);
        console.log(props)
        if(props.json){
            this.data = props.json
            this.id=props.json.id;
            this.active = props.json.active;
            this.views = props.json.views;
            this.viewsTrend = props.json.viewsTrend;
            if(props.json.avatar && props.json.avatar.bytes){
                this.avatar="data:"+ props.json.avatar.contentType+";base64,"+props.json.avatar.bytes;
            }else
                this.avatar = props.json.avatar;
            this.name = props.json.firstName+" "+props.json.lastName;
            this.age = props.json.age+" years old";
            this.price=props.json.price
            this.experience=props.json.experience;
            this.location=props.json.location;
            this.employmentType=props.json.employmentType
            this.relocation=props.json.relocation
            this.text = props.json.text;
            this.ratings=props.json.ratings;
            if(props.status)
                this.status= props.status;
            else
                this.status=props.json.status;
            this.actions=""
            let user = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : undefined;
            if(user && props.json.recommender && props.json.recommender.id==user.id){
                this.actions=this.actions+" edit delete"+(this.active ? " hide" : " publish");
            }
        }else {
            this.active = props.active;
            this.actions = props.actions ? props.actions : "";
            this.views = props.views;
            this.viewsTrend = props.viewsTrend;
            this.avatar = props.avatar;
            this.name = props.name;
            this.age = props.age;
            this.text = props.text;
            this.ratings=props.ratings;
            this.price= props.price
            this.status= props.status;
        }
        this.job= this.props.job;
        this.state = {
            id: this.id,
            actions: this.actions,
            showStatusEditor: false,
            status: this.status
        }
        if(this.status){
            this.status_options.forEach(opt=>{
                if(opt.value==this.status)
                    this.defaultStatus=opt;
            })
        }
        if(!this.avatar){
            this.avatar="/api/recommendations/"+this.id+"/avatar/image"
        }
    }
    onClick = () => {
        if(!this.state.showStatusEditor){
            window.location='/recommendation/'+this.id
        }
    }
    async handleClickStatus(e) {
        e.stopPropagation();
        this.state.showStatusEditor=true
        this.setState(this.state)
        console.log(e)
    }
    setSelected(value){
        console.log(value,this)
    }
    async onChangeStatus (value) {
        console.log(value,this)
        this.state.showStatusEditor=false
        this.state.status=value.label
        let self=this
        let params={jobId: this.job, status: value.value}
        console.log("change status",params)
        Backend.do("POST", "/recommendations/"+this.id+"/status", params, function (response) {
            console.log(response);
            if (response.error || response.errors) {
                $('.error').removeClass('d-none').text(response.errors ? response.errors : response.error)
            } else {
                self.setState(self.state)
            }
        })
    }
    async handleClickHide(e) {
        e.stopPropagation();
        let params = {id:this.state.id, active: false};
        let self = this;
        await Backend.do("POST","/recommendations/activation", params, function (response) {
            self.state.actions = self.state.actions.replace(' hide', '') + " publish";
            self.setState(self.state)
        })
    }

    async handleClickPublish(e) {
        e.stopPropagation();
        let params = {id:this.state.id, active: true};
        let c = this;
        await Backend.do("POST","/recommendations/activation", params, function (response) {
            c.state.actions = c.state.actions.replace(' publish', '') + " hide";
            c.setState(c.state)
        })
    }

    render() {
        return (
          <div className="card recommendation-short bg-white m-2" onClick={() => {this.onClick()}}>
              <div className="card-header d-none d-md-block d-flex justify-content-between align-items-center">
                  <span className="views">
                      {this.viewsTrend > 0 &&
                          <span className="trend-up">
                              {this.views}
                          </span>
                      }
                      {this.viewsTrend < 0 &&
                          <span className="trend-down">
                              {this.views}
                          </span>
                      }
                      <span className="label">
                        {this.views > 0 ? " views" : (this.views==0 ? "no views" : "")}
                      </span>
                  </span>
              </div>
              <div className="card-body text-truncate-container">
                  {
                      this.state.status &&
                      <div className={"float-end w-50"} onClick={e => { e.preventDefault(); e.stopPropagation(); }}>
                          { !this.state.showStatusEditor &&
                          <button onClick={((e) => this.handleClickStatus(e))} className={"active btn "+(this.state.status=='New' ? 'btn-primary' : 'btn-secondary')+" btn-rounded  rounded-pill " }>{this.state.status}</button>
                          }
                          {
                              this.state.showStatusEditor &&
                          <CreatableSelect
                              /*isClearable*/
                              /*isMulti*/
                              cacheOptions
                              defaultOptions
                              value={this.defaultStatus}
                              options={this.status_options}
                              components={animatedComponents}
                              value={this.state.status}
                              onChange={e => this.onChangeStatus(e)}
                              theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 10,
                                  colors: {
                                      ...theme.colors,
                                      primary25: '#FCA187FF',
                                      primary: '#FF855E',
                                  },
                              })
                              }
                          />
                          }
                      </div>
                  }
                  <div className="card-title user-badge">
                      <div className="user-badge d-inline-flex w-100 d-flex align-items-center">
                          <img src={this.avatar} className="avatar align-top  mt-2"/>
                          <div className={"user-info ms-2"}>
                              <div className="user-name">{this.name}</div>
                              <div className={"mt-1 age"+ (this.avatar? "" : " mt-3 mb-3")}>
                                  {this.age}
                              </div>
                              {this.price>0 &&
                                  <div className={"mt-1 price"+ (this.avatar? "" : " mt-3 mb-3")}>
                                      $ {this.price}
                                  </div>
                              }
                          </div>
                      </div>
                  </div>
                  <p className="d-none d-md-block card-text">
                      {this.text}
                  </p>
              </div>
              <div className="card-footer text-muted">
                  {this.state.actions.includes("edit") &&
                      <a href={'/recommendation/edit/'+this.id} className=" btn btn-grey rounded-circle me-2 p-1" ><img className="left"
                                                                                          src="/images/edit.png"/></a>
                  }
                  {this.state.actions.includes("publish") &&
                      <a href="#" onClick={((e) => this.handleClickPublish(e))} className="publish btn btn-primary rounded-pill ps-3 pe-3">Publish<img
                          src="/images/arrow2.png"/></a>
                  }
                  {this.state.actions.includes("hide") &&
                      <a href="#" onClick={((e) => this.handleClickHide(e))} className="hide btn btn-secondary rounded-pill"><img className="left"
                          src="/images/hide.png"/>Hide</a>
                  }
              </div>
          </div>
        );
    }

}
export default RecommendationShort;