// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all and (min-height: 500px) {
.home-title {
    top: 105px;
    width: 100%;
    font-weight: 800;
    font-size: 72px;
    line-height: 111%;
    }
    .face1-container, .face2-container , .face3-container  {
           width: 268px;
           height: 300px;
           bottom: -45px;
    }
    .arrow-container{
        bottom: 410px;
    }
     .arrow-container .align-items-end {
         margin-left: 0;
    }
    .find-container {
        bottom: 111px;
    }
   .about .faces-container img{
       width: 1200px;
   }
}
`, "",{"version":3,"sources":["webpack://./src/scss/media_height_500.css"],"names":[],"mappings":"AAAA;AACA;IACI,UAAU;IACV,WAAW;IACX,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB;IACA;WACO,YAAY;WACZ,aAAa;WACb,aAAa;IACpB;IACA;QACI,aAAa;IACjB;KACC;SACI,cAAc;IACnB;IACA;QACI,aAAa;IACjB;GACD;OACI,aAAa;GACjB;AACH","sourcesContent":["@media all and (min-height: 500px) {\n.home-title {\n    top: 105px;\n    width: 100%;\n    font-weight: 800;\n    font-size: 72px;\n    line-height: 111%;\n    }\n    .face1-container, .face2-container , .face3-container  {\n           width: 268px;\n           height: 300px;\n           bottom: -45px;\n    }\n    .arrow-container{\n        bottom: 410px;\n    }\n     .arrow-container .align-items-end {\n         margin-left: 0;\n    }\n    .find-container {\n        bottom: 111px;\n    }\n   .about .faces-container img{\n       width: 1200px;\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
